import axios from 'axios';
import { cloneDeep } from 'lodash';

export class HttpService {
    static tokenValue = null;
    static userToken = null;
    static logoutReached = false;
    static  token() {
        return  this.tokenValue;
    }

    static  getUserToken() {
        return  this.userToken;
    }
    static get(url, params, isAuth, headers,customErrorMessage) {
       return this.processRequest('get',url,params, undefined,isAuth,headers, customErrorMessage);
    }

    static post(url, data, isAuth, headers,customErrorMessage) {

     return this.processRequest('post',url,undefined, data,isAuth,headers, customErrorMessage);
    }

    static put(url, data, isAuth, headers,customErrorMessage) {
       return  this.processRequest('put',url,undefined, data,isAuth,headers, customErrorMessage);
    }

    static delete(url, params, isAuth, headers,customErrorMessage) {
        return this.processRequest('delete',url,params, undefined,isAuth,headers, customErrorMessage);
    }


    static processAuthHeader(isAuth, headers) {
        if (isAuth) {
            const token = this.token();


            if (!token) {
                throw 'Token Not Found';
            }
            headers['Authorization'] = token;
     
            

        }
        return headers;
    }

    static async processRequest(method, url, params,data, isAuth, headers , customErrorMessage) {
        const userToken = this.getUserToken();
        if(userToken) {
            if(!data) {
                data = new FormData();
            }
            data.append('usertoken',userToken);
        }

        try{
            const response = await axios.request({
                url,
                method,
                headers:   this.processAuthHeader(isAuth, headers),
                data,
                params
            });
     
            if(this.responseHasError(response)){
                throw {response};
            } 
            return response;
        } catch(e) {
            if(e.response && e.response.data && e.response.data.logout) {
                if(!this.logoutReached) {
                    this.logoutReached = true;
                    return await this.processRequest(method, url, params,data, isAuth, headers , customErrorMessage);
                } 
                
            }
            this.logoutReached = false;
            if (this.responseHasError(e.response,true)) {
                throw (e.response && e.response.data &&  e.response.data.error ? e.response.data.error : customErrorMessage )
            }

            throw customErrorMessage;
        }
        
      

    }

    static responseHasError(response, updateOriginal){
        if(!response || response.data === undefined || response.data === null){

            return true;
        }
        if(typeof response.data === "string"){
            return false
        }
        let responseData = cloneDeep(response.data);
        if(Array.isArray(responseData)){
            responseData = responseData[0];
        }
        if(updateOriginal) {
            response.data = responseData;
        }
        if (responseData.error) {
            return true;
        } else {
            return false;
        }
    }
}