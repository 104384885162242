import React from 'react';
import { Table } from 'antd';
import 'antd/dist/antd.css';
import { clearUnits } from '../../utils/numbersFormat';
import { getDataSource } from '../../utils/insightsTableConstructor';

const BrandInsight = ({ brandInsightsData, getModelInsights, brand }) => {
  const sorterCompare = ( a, b, key, isNumeric = 0 ) => {
    const sideA = ( isNumeric !== 0 ? Number( clearUnits( a[key] ) ) : a[key] );
    const sideB = ( isNumeric !== 0 ? Number( clearUnits( b[key] ) ) : b[key] );

    if ( sideA < sideB ) return -1;
    if ( sideA > sideB ) return 1;
    return 0;
  }
  const columns = [
    { title: 'Model', key: 'model', dataIndex: 'title', 
      sorter: { compare: (a, b) => sorterCompare( a, b, 'title', 0 ), multiple: 1 },
      filters: brandInsightsData?.map( entry => { return { text: entry.model, value: entry.model } } ).sort( (a,b) => sorterCompare( a, b, 'text', 0 ) ),
      onFilter: (value, record) => record.title === value, filterSearch: true,
      render: ( text, record ) => ( <div onClick={() => getModelInsights(brand, record.title)} className="text-blue-600 cursor-pointer"> {text} </div> )
    },
    { title: '% of Evals', key: 'percentageOfEvals', dataIndex: 'percentageOfEvals', align: 'center', sorter: { compare: (a, b) => sorterCompare( a, b, 'percentageOfEvals', 1 ), multiple: 2 } },
    { title: '% Appraisals at Own Dealers', key: 'percentageOwnDealers', dataIndex: 'percentageOwnDealers', align: 'center', sorter: { compare: (a, b) => sorterCompare( a, b, 'percentageOwnDealers', 1 ), multiple: 3 } },
    { title: 'Average Price', key: 'averagePrice', dataIndex: 'averagePrice',
      sorter: { compare: (a, b) => sorterCompare( a, b, 'averagePrice', 1 ), multiple:4 }, align: 'center'
    },
    { title: 'Average Age', key: 'averageAge', dataIndex: 'averageAge', align: 'center', sorter: { compare: (a, b) => sorterCompare( a, b, 'averageAge', 1 ), multiple: 5 } },
    { title: '% Traded', key: 'percentageTraded', dataIndex: 'percentageTraded', align: 'center', sorter: { compare: (a, b) => sorterCompare( a, b, 'percentageTraded', 1 ), multiple: 6 } },
  ];

  const dataSource = getDataSource( brandInsightsData, 'model' ).sort( ( a,b ) => sorterCompare( a, b, 'title', 0 ) );
  return (
    <div className="flex flex-col">
      <div className="overflow-x-auto sm:-mx-6 lg:-mx-8 my-2">
        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
            <Table dataSource={dataSource} columns={columns} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default BrandInsight
