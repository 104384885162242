import React from 'react';

const PricesPerStateTable = ({ priceList }) => {
    const priceAverage = priceList.map((price) => price.value).reduce((a, b) => a + b, 0) / priceList.length;
    return (
        <table className="border-collapse table-auto w-full">
            <tbody>
                {priceList.map((price, index) => (
                    <tr key={price.id} className={`border-b ${index % 2 === 0 ? 'bg-white' : 'bg-gray-100'}`}>
                        <th>{price.name}</th>
                        <td className={`text-right ${price.value > priceAverage ? 'text-green-500' : 'text-red-500'}`}>
                            {price.value &&
                                price.value.toLocaleString('en-US', {
                                    style: 'currency',
                                    currency: 'AUD',
                                    currencyDisplay: 'code',
                                    maximumFractionDigits: 0,
                                })}
                        </td>
                    </tr>
                ))}
            </tbody>
        </table>
    );
};

export default PricesPerStateTable;
