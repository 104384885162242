import React from "react";
import './Card.scss'

export function CardComponent(props) {
    const getSkeletons = () => {
        const cnt = props.loadingSkeletons || 3;
        const arr = props.skeletonsHeights || [...Array( cnt )];
        return (
            <span className="mt-1 text-md text-gray-900 sm:mt-0 ">
                { arr.map( ( val, index ) => <nb-skeleton key={`skeleton-${index}`} custom-styles='{"background-color": "#f2f2f2"}' height={ !!val ? `${val}px` : '50px' } variant="rect"></nb-skeleton> ) }
            </span>
        )
    }

    const getTitle = ( title ) => {
        return ( !!title ? <h3 className="text-lg leading-6 font-medium text-gray-900 mb-2">{title}</h3> : '' )
    }
    return (
        <div className={ `shadow bg-white px-4 py-4 text-left sm:p-4 ${ props.className || '' }` }>
            { getTitle( props.title || '' ) }
            { props.loading ? ( getSkeletons()  ) : ( props.children ) }
        </div>
    );
}
