import React, { useEffect, useState, useRef, useLayoutEffect } from 'react';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';

const colors = [ '#beb6f2', '#6cd9d2', '#005ce6', '#bbcc99', '#40d9ff', '#f032e6', '#cc99bb', '#30bf30', '#b300bf', '#bf9c8f'];
const InsightsResult = ({ chartData, brands }) => {

    const chart = useRef(null);
    const [ chartEmpty, setChartEmpty ] = useState( false );
    useEffect(() => {
        let x = am4core.create("evalChangesChart", am4charts.XYChart);
        chart.current = x;
        chart.current.scrollbarX = new am4core.Scrollbar();
        chart.current.legend = new am4charts.Legend();
        chart.current.cursor = new am4charts.XYCursor();
        
        
        return () => {
            x.dispose();
        };
    }, []);

    useEffect(() => {
        if ( !Array.isArray(chartData) ) return;
        setChartEmpty( chartData.length === 0 || chartData.reduce( (a,b) => [ ...a, ...b ] ).length === 0 );
        chart.current.colors.list = colors.slice(0, chartData.length - 1).concat('#c62828').map( color => am4core.color( color ) ); 
        let mappedChartData = {};
        chartData.forEach( ( dataRow, brandIdx ) => {
            dataRow.forEach((point) => {
                if ( !mappedChartData[point.date] ) mappedChartData[point.date] = {};
                mappedChartData[point.date][`brand-${brandIdx}`] = point.value;
            });
        });
        const data = [];
        Object.keys( mappedChartData ).forEach( date => {
            const point = mappedChartData[date];
            point.date = date;
            data.push( point );
        } );
        data.sort( ( a,b ) => {
            return new Date( a.date ).getTime() - new Date( b.date ).getTime();
        } );
        chart.current.data = data;

                
        // Create axes
        chart.current.xAxes.push(new am4charts.DateAxis());
        chart.current.yAxes.push(new am4charts.ValueAxis());
        brands.forEach( (brand, idx) => {
            createSeries(`brand-${idx}`, brand.name);
        } );
        createSeries(`brand-${chartData.length - 1}`, 'Industry Average');
    }, [chartData]);


    // Create series
    function createSeries(field, name) {
        let series = chart.current.series.push(new am4charts.LineSeries());
        series.dataFields.valueY = field;
        series.dataFields.dateX = "date";
        series.strokeWidth = 2;
        series.name = name;
        series.tooltipText = "{dateFormatted} Percentage: [bold]{valueY}%[/bold][/]";
        series.tensionX = 0.8;
        series.showOnInit = true;
        series.smoothing = "monotoneX";

        series.tooltipText = "{name}: {valueY}";
        var bullet = series.bullets.push(new am4charts.CircleBullet());
        bullet.circle.stroke = am4core.color("#fff");
        bullet.circle.strokeWidth = 2;

        series.tooltip.label.adapter.add("textOutput", (text, target, key) => {
            if (target?.dataItem?.values?.valueY?.value > 0) {
                return `${text.split(":")[0]}: +${target?.dataItem?.values?.valueY?.value}%`;
            }
            return text + '%';
        });

        
        return series;
    }


    return (
        <div>
            <div id="evalChangesChart" className={ chartEmpty ? "hidden" : ""} style={{height: '450px'}}></div>
            <div className={ chartEmpty ? "" : "hidden"} style={{height: '100px'}}>
                <div className="bg-orange-100 border-l-4 border-orange-500 text-orange-700 p-5 my-5" role="alert" >No data found during this selected period.</div>
            </div>
        </div>
    );
};

export default InsightsResult;
