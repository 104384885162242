import React from 'react';

const RadioBtns = ({ setVehicleSelectionMode, vehicleSelectionMode }) => {

    return (
        <div
            className="carpicker-mode-selector  space-y-4 flex flex-row items-baseline mb-10 md:mx-10"
            onChange={(e) => setVehicleSelectionMode(e.target.value)}
        >
             <div className="flex items-center mr-4">
                <input
                    className="focus:ring-primary-500 h-4 w-4 text-primary-600 border-gray-300 rounded"
                    id="registration-radio"
                    type="radio"
                    value="registration"
                    name="mode"
                    checked={vehicleSelectionMode === 'registration'}
                />
                <label className="ml-3 block text-sm font-medium text-gray-700" htmlFor="registration-radio">
                    Registration
                </label>
            </div>            
            <div className="flex items-center mr-4">
                <input
                    className="focus:ring-primary-500 h-4 w-4 text-primary-600 border-gray-300 rounded"
                    id="vin-radio"
                    type="radio"
                    value="vin"
                    name="mode"
                    checked={vehicleSelectionMode === 'vin'}
                />
                <label className="ml-3 block text-sm font-medium text-gray-700" htmlFor="vin-radio">
                    VIN
                </label>
            </div>
            <div className="flex items-center mr-4">
                <input
                    className="focus:ring-primary-500 h-4 w-4 text-primary-600 border-gray-300 rounded"
                    id="manual-radio"
                    type="radio"
                    value="manual"
                    name="mode"
                    checked={vehicleSelectionMode === 'manual'}
                />
                <label className="ml-3 block text-sm font-medium text-gray-700" htmlFor="manual-radio">
                    Manual
                </label>
            </div>
        </div>
    );
};

export default RadioBtns;
