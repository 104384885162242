import { std } from 'mathjs';

export const formatWithCurrency = ( number, currency = 'AED' ) => {
    if ( number !== 0 && ( !number || !parseInt(number) ) ) return 'N/A';
    if ( currency === '$' ) currency = 'USD';
    return parseInt(number).toLocaleString( 'en-US', { style: 'currency', currency: currency.trim(), minimumFractionDigits: 0, maximumFractionDigits: 0 } );
}

export const formatNumber = ( number ) => {
    if ( !number && number != 0 ) return 'N/A';
    return parseInt(number).toLocaleString( undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0 } );
}

export const roundNumber = ( number, rounding = 1 ) => {
    if ( number !== 0 && ( !number || !parseInt( number ) ) ) return 'N/A';
    return Math.round( parseInt( number ) / rounding ) * rounding;
}

export const roundNumberWithAbsMin = ( number, rounding = 1, absMin = false ) => {
    if ( !number || !parseInt( number ) ) return 'N/A';
    if ( absMin === false ) return roundNumber( number, rounding );
    if ( Math.abs( number ) < rounding ) return ( number < 0 ? -absMin : absMin );
    number = Math.round( parseInt( number ) / rounding ) * rounding;
    if ( number < 0 ) {
        number = Math.min( number, -absMin );
    } else {
        number = Math.max( number, absMin );
    }
    return number;
}

export const formatNumberWithUnit = ( number, unit, prefix = 1 ) => {
    return prefix ? `${unit} ${ formatNumber( number ) }`: `${ formatNumber( number ) } ${unit}`;
}

export const formatDecimalWithUnit = ( number, unit, prefix = 1 ) => {
    if ( !parseFloat( number ) && parseInt( number ) !== 0 ) return 'N/A';
    const formatted = (parseFloat(number) ).toLocaleString( undefined , { style: 'decimal', maximumFractionDigits : 2, minimumFractionDigits : 2 });
    return prefix ? `${unit} ${ formatted }`: `${ formatted } ${unit}`;
}

export const formatRangeWithCurrency = ( range, currency = 'AED' ) => {
    return `${ formatWithCurrency( range[0], currency ) } - ${ formatWithCurrency( range[1], currency ) }`;
}

export const formatRange = ( range, currency = 'AED' ) => {
    return `${ formatNumber( range[0], currency ) } - ${ formatNumber( range[1], currency ) }`;
}

export const roundRange = ( range, rounding = 1 ) => {
    return `${ roundNumber( range[0], rounding ) } - ${ roundNumber( range[1], rounding ) }`;
}

export const formatRangeWithUnit = ( range, unit, prefix = 1 ) => {
    return `${ formatNumberWithUnit( range[0], unit, prefix ) } - ${ formatNumberWithUnit( range[1], unit, prefix ) }`;
}

export const detectColor = ( value ) => {
    if ( !!parseInt(value) || value === 0 ) {
        if ( value < 0 ) return 'red';
        if ( value > 0 ) return 'green';
        return 'black';
    }
    if ( value === 'very high' || value === 'high' ) return 'green';
    if ( value === 'very low' || value === 'low' ) return 'red';
    if ( value === 'mid' ) return 'yellow';
}

export const formatSingleAmount = ( amount, currency ) => {
    if ( parseInt(amount) === 0 ) return '-';
    return ( amount > 0 ? '+' : '' ) + formatWithCurrency( amount, currency );
}

export const filterOutliers = (numArray, marginPercentage = 2) => {  
    const uniqueValues = numArray.filter((v,index) => numArray.indexOf(v) === index);
    if(uniqueValues.length < 6) {
        return numArray;
    }

    const average =   numArray.reduce((a, b) => (a + b)) / numArray.length;
    const standardDeviation = std(numArray);

    let max = average + (standardDeviation * marginPercentage );
    let min = average - (standardDeviation * marginPercentage );


    const originalFiltered  = numArray.filter(v => v>= min && v<=max );
    return originalFiltered;
}

export const removeRandom = ( numArray ) => {
    if ( numArray.length < 8 ) return numArray;
    const validX = filterOutliers( numArray.map( num => num.ax ).filter( val => val >= 1000 ) );
    const validY = filterOutliers( numArray.map( num => num.ay ).filter( val => val >= 1000 ) );
    const finalNumArray = [];
    numArray.forEach( num => {
        if ( validX.indexOf( num.ax ) >= 0 && validY.indexOf( num.ay ) >= 0 ) finalNumArray.push( num );
    } );
    return finalNumArray;
}

export const clearUnits = ( num ) => {
    return ( num || '' ).trim().replaceAll(/[^0-9&&^.]/g, "")
}