import React, { useEffect, useState, useRef } from 'react';

import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import api from '../../api/priceGuide';
import Select from 'react-select';
import {
    formatNumber,
    formatNumberWithUnit,
    formatWithCurrency,
    removeRandom,
    roundNumber,
} from '../../utils/numbersFormat';
import { XYChartLoaderComponent } from '../Loaders/XYChartLoader/XYChartLoader';
import Tooltip from './Tootltip';

const plotTypeOptions = [
    {
        name: 'similar',
        description: 'Cars with the same year, make and model.',
    },
    {
        name: 'identical',
        description: 'Cars with the same year, make, model, variant, engine, transmission, drive train and model year.',
    },
];

const plotDaysOptions = [30, 90, 180];

const Scatter = ({ vehicleCode, avgMileageRange, tradePriceRange }) => {
    const [plotType, setPlotType] = useState('similar');
    const [plotDays, setPlotDays] = useState(90);
    const [plotData, setPlotData] = useState(false);
    const [plots, setPlots] = useState(false);
    const [scatterdivLoading, setScatterdivLoading] = useState(true);

    useEffect(() => {
        if (!scatterdivLoading && tradePriceRange) {
            if (plotData) {
                const chart = am4core.create('scatterdiv', am4charts.XYChart);

                chart.data = plotData.map((entry) => {
                    return {
                        ...entry,
                        formattedMileage: `${formatNumberWithUnit(roundNumber(entry.ax, 1000), 'KM', 0)}`,
                        formattedPrice: `${formatWithCurrency(roundNumber(entry.ay, 100), 'USD')}`,
                    };
                });

                // Create axes
                const valueAxisX = chart.xAxes.push(new am4charts.ValueAxis());
                valueAxisX.title.text = 'Kilometers';
                valueAxisX.renderer.minGridDistance = 80;
                valueAxisX.title.fontWeight = 'bold';

                // Create value axis
                const valueAxisY = chart.yAxes.push(new am4charts.ValueAxis());
                valueAxisY.title.text = 'Price';
                valueAxisY.title.fontWeight = 'bold';

                // Create series
                const lineSeries = chart.series.push(new am4charts.LineSeries());
                lineSeries.dataFields.valueY = 'ay';
                lineSeries.dataFields.valueX = 'ax';
                lineSeries.strokeOpacity = 0;

                // Enable Scroll
                chart.scrollbarX = new am4charts.XYChartScrollbar();
                chart.scrollbarX.series.push(lineSeries);

                // Add a bullet
                const bullet = lineSeries.bullets.push(new am4charts.Bullet());

                bullet.tooltipHTML = `<div align="left" style="color:black">Mileage: {formattedMileage}<br>Price: {formattedPrice}</div>`;

                // Tooltip
                lineSeries.tooltip.label.textAlign = 'middle';
                lineSeries.tooltip.pointerOrientation = 'down';
                lineSeries.tooltip.dy = -5;

                bullet.propertyFields.showTooltipOn = 'showTooltip';
                lineSeries.tooltip.propertyFields.pointerOrientation = 'orientation';
                lineSeries.tooltip.propertyFields.dy = 'offset';

                // Add a triangle to act as am arrow
                const arrow = bullet.createChild(am4core.Circle);
                arrow.horizontalCenter = 'middle';
                arrow.verticalCenter = 'middle';
                arrow.stroke = am4core.color('#0066a5');
                arrow.strokeWidth = 1;
                arrow.fill = chart.colors.getIndex(0);
                arrow.direction = 'top';
                arrow.width = 12;
                arrow.height = 12;

                if (tradePriceRange) {
                    // Create the Oval Trade Price Area
                    const tradePriceArea = chart.series.push(new am4charts.ColumnSeries());
                    tradePriceArea.dataFields.valueY = 'y1';
                    tradePriceArea.dataFields.valueX = 'x1';
                    tradePriceArea.dataFields.openValueY = 'y2';
                    tradePriceArea.dataFields.openValueX = 'x2';
                    tradePriceArea.data = [
                        {
                            x1: avgMileageRange[0],
                            y1: tradePriceRange[0],
                            x2: avgMileageRange[1],
                            y2: tradePriceRange[1],
                        },
                    ];
                    tradePriceArea.columns.template.fillOpacity = 0;
                    tradePriceArea.columns.template.strokeOpacity = 0;
                    tradePriceArea.columns.template.className = 'STYLES';
                    tradePriceArea.columns.template.tooltipHTML = `<div align="left" style="color:black">Trade Price Area</div>`;
                    const oval = tradePriceArea.columns.template.createChild(am4core.Ellipse);
                    oval.width = '100%';
                    oval.height = '100%';
                    oval.fill = am4core.color('#0066a5');
                    oval.fillOpacity = 0.2;
                    oval.x = '50%';
                    oval.y = '50%';
                }
            } else if (plotData === null) {
                document.querySelector('.scatterdiv').innerHTML = 'No data available';
            }
        }
    }, [tradePriceRange, plotData, scatterdivLoading]);

    useEffect(() => {
        getPlots(vehicleCode);
    }, []);
    useEffect(() => {
        if (plotData) setScatterdivLoading(false);
    }, [plotData, tradePriceRange]);

    useEffect(() => {
        setScatterdivLoading(true);
        if (!plots) return;
        if (dataExists() && checkPlotDaysDisabled(plotDays)) {
            for (const days of plotDaysOptions) {
                if (checkPlotDaysDisabled(days)) continue;
                setPlotDays(days);
                setPlotData(plots[plotDays][plotType]);
                break;
            }
        } else {
            setPlotData(plots[plotDays][plotType]);
        }
    }, [plots, plotDays, plotType]);

    const getPlots = async (vehicleKey) => {
        const finalPlots = {};
        await Promise.all(
            plotDaysOptions.map(async (days) => {
                const res = await api.getPricesForSimilarEvalsAU(vehicleKey, days);
                finalPlots[days] = {};
                finalPlots[days]['similar'] = Array.isArray(res[0]) ? removeRandom(res[0]) : [];
                finalPlots[days]['identical'] = Array.isArray(res[1]) ? removeRandom(res[1]) : [];
            }),
        );
        if (Array.isArray(finalPlots[plotDays]['identical']) && finalPlots[plotDays]['identical'].length > 5) {
            setPlotType('identical');
        }
        setPlots(finalPlots);
    };

    const renderPlotTypeSelector = () => {
        return plotTypeOptions.map((option) => {
            return (
                Array.isArray(plots[plotDays][option.name]) &&
                plots[plotDays][option.name].length > 0 && (
                    <div key={`plot-type-selector-${option.name}`} className="flex items-center ml-4">
                        <input
                            className="focus:ring-primary-500 h-4 w-4 text-primary-600 border-gray-300"
                            id="similar-radio"
                            type="radio"
                            value="similar"
                            name="mode"
                            checked={plotType === option.name}
                            onChange={() => setPlotType(option.name)}
                        />
                        <label
                            className="ml-3 block text-sm font-medium text-gray-700 capitalize flex items-center"
                            htmlFor="similar-radio"
                        >
                            <strong>{option.name}</strong>
                            <span className="ml-2">
                                <Tooltip>{option.description}</Tooltip>
                            </span>
                        </label>
                    </div>
                )
            );
        });
    };

    const checkPlotDaysDisabled = (days) => {
        return (
            (!Array.isArray(plots[days]['similar']) || plots[days]['similar'].length === 0) &&
            (!Array.isArray(plots[days]['identical']) || plots[days]['identical'].length === 0)
        );
    };

    const dataExists = () => {
        let yup = false;
        for (const days of plotDaysOptions) {
            yup = yup || !checkPlotDaysDisabled(days);
        }
        return yup;
    };

    const renderPlotDaySelector = () => {
        return (
            <Select
                className="w-full"
                options={plotDaysOptions.map((days) => {
                    return { label: `Last ${days} days`, value: days, isDisabled: checkPlotDaysDisabled(days) };
                })}
                onChange={(selected) => setPlotDays(selected.value)}
                value={{ label: `Last ${plotDays} days`, value: plotDays, isDisabled: checkPlotDaysDisabled(plotDays) }}
            />
        );
    };

    return (
        <div>
            {!scatterdivLoading && (
                <div
                    className="bg-orange-100 border-l-4 border-orange-500 text-orange-700 p-5 my-5"
                    role="alert"
                    style={{ display: !dataExists() ? 'block' : 'none' }}
                >
                    No data found for this vehicle.
                </div>
            )}
            {!scatterdivLoading && (
                <div className="h-full" style={{ display: dataExists() ? 'block' : 'none' }}>
                    <div className="carpicker-mode-selector mt-4 space-y-4 flex justify-between items-center">
                        <div className="flex flex-col gap-2 flex-1">{renderPlotTypeSelector()}</div>
                        <div className="w-1/3" style={{ marginTop: '0' }}>
                            {renderPlotDaySelector()}
                        </div>
                    </div>
                    <div className="scatterdiv" style={{ height: 'calc( 70vh - 180px )', minHeight: '400px' }}></div>
                </div>
            )}
            {scatterdivLoading && (
                <div className="mt-4" style={{ paddingTop: '2rem' }}>
                    <nb-skeleton
                        custom-styles='{"background-color": "#f2f2f2"}'
                        height="70px"
                        variant="rect"
                    ></nb-skeleton>
                    <XYChartLoaderComponent
                        height="450px"
                        className="w-full mt-6"
                        chartId="vehicleScatterLoader"
                        chartType="scatter"
                    ></XYChartLoaderComponent>
                </div>
            )}
        </div>
    );
};

export default Scatter;
