import React from "react";
import ManualCarPicker from "./ManualCarPicker";
import RadioBtns from "./RadioBtns";
import RegistrationCarPicker from "./RegistrationCarPicker";
import VinCarPicker from "./VinCarPicker";

const CarPicker = ({setCarObject, setVehicleSelectionMode, vehicleSelectionMode, loading}) => {

  return (
    <>
      <div className="md:mx-10">
        <h3 className="text-lg font-medium leading-6 text-gray-900">Search By:</h3>
      </div>
      <RadioBtns setVehicleSelectionMode={setVehicleSelectionMode} vehicleSelectionMode={vehicleSelectionMode} />
      {vehicleSelectionMode === "manual" && <ManualCarPicker setCarObject={setCarObject}/>}
      {vehicleSelectionMode === "vin" && <VinCarPicker setCarObject={setCarObject}/>}
      {vehicleSelectionMode === "registration" && <RegistrationCarPicker setCarObject={setCarObject} />}
    </>
  );
};

export default CarPicker;
