import React, { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import api from '../../api/priceGuide';
import { defineCustomElements as initSkeleton } from 'skeleton-webcomponent-loader/loader';
import Scatter from './Scatter';
import Map from './Map';
import {
    detectColor,
    formatNumber,
    formatRangeWithCurrency,
    formatRangeWithUnit,
    formatSingleAmount,
    roundNumber,
    roundRange,
} from '../../utils/numbersFormat';
import { CardComponent } from '../Card/Card';
import { UserContext } from '../../contexts/userContext';
import Tooltip from './Tootltip';
import { isValidColor } from '../../utils/helpers';
import { PlusCircleIcon, MinusCircleIcon } from '@heroicons/react/outline';
import PricesPerStateTable from './PricesPerStateTable';

const avgLevels = ['Average', 'Below Avg.', 'Above Avg.'];

const PriceGuideResult = () => {
    const { user } = useContext(UserContext);
    const { data } = useParams();

    const inputs = JSON.parse(atob(data));
    const km = inputs.km;
    const vehicleCode = inputs.carData.id;
    const carObject = inputs.carData;
    const [priceGuideResponse, setPriceGuideResponse] = useState(false);
    const [tradePriceBreakdown, setTradePriceBreakdown] = useState(false);
    const [breakDownRevealed, setBreakDownRevealed] = useState(false);
    const [avgMileage, setAvgMileage] = useState([]);
    const [tradePriceRange, setTradePriceRange] = useState(null);
    const [avgLevel, setAvgLevel] = useState(avgLevels[0]);
    const [prices, setPrices] = useState(false);
    const [priceList, setPriceList] = useState(null);

    const currency = user.ccy;

    useEffect(() => {
        setPriceGuideResponse(false);
        initSkeleton();
        getPriceGuideAU(vehicleCode, km, user.state);
        (async () => {
            const prices = await api.getAUPriceForEachState(vehicleCode, km);
            if (Array.isArray(prices) && prices.length > 0) setPriceList(prices.sort((a, b) => b.value - a.value));
        })();
    }, []);

    //price api call
    const getPriceGuideAU = async (vehicleKey, km, state) => {
        let res = await api.getAUPrice(vehicleKey, km, state);
        if (!Array.isArray(res?.confidence)) {
            res = {
                explainability: { Mileage: '', MileagePerMonth: '', TopDealerID: '', State: '' },
                confidence: ['', ''],
            };
        }

        if (!!res?.average_mileage) {
            setAvgMileage(res.average_mileage_interval);
            const avg = (res.average_mileage_interval[0] + res.average_mileage_interval[1]) / 2;
            if (km && km > avg) setAvgLevel(avgLevels[2]);
            if (km && km < avg) setAvgLevel(avgLevels[1]);
        }

        if (res.confidence.length === 2) {
            const ex = res.explainability;
            const mileage = roundNumber(ex.Mileage, 100);
            const dealer = roundNumber(ex.TopDealerID, 100);
            const state = roundNumber(ex.State, 100);
            const color = roundNumber(ex.ExtColour, 100);
            const basePrice = res.confidence.map((val) => val - mileage - dealer - state - color);
            setTradePriceRange(res.confidence);
            setPriceGuideResponse({
                confidence: {
                    label: 'Trade Price',
                    value: formatRangeWithCurrency(roundRange(res.confidence, 100).split(' - '), currency),
                    color: 'black',
                    additionalSyles: ' mt-5 font-bold ',
                    tooltip: 'The wholesale or trade in price of the car (after adjustments)',
                },
                confidence_level: {
                    label: 'Level of Certainty',
                    value: res.confidence_level,
                    color: detectColor(res.confidence_level),
                    additionalSyles: ' mt-5 capitalize font-medium ',
                },
            });
            setTradePriceBreakdown({
                basePrice: {
                    label: 'Base price',
                    value: formatRangeWithCurrency(roundRange(basePrice, 100).split(' - '), currency),
                    additionalSyles: '',
                    color: 'black',
                    tooltip: 'The average wholesale or trade in price of the car before adjustments.',
                },
                mileage: {
                    label: 'Mileage',
                    value: formatSingleAmount(mileage, currency),
                    color: detectColor(mileage),
                    tooltip:
                        'The price adjustment for the car based on how much above or below the average mileage it has travelled.',
                },
                dealer: {
                    label: 'Dealer',
                    value: formatSingleAmount(dealer, currency),
                    color: detectColor(dealer),
                    tooltip:
                        'The price adjustment for the car based on the success the dealer had in selling similar cars previously.',
                },
                state: {
                    label: 'State',
                    value: formatSingleAmount(state, currency),
                    color: detectColor(state),
                    tooltip:
                        'The price adjustment for the car above or below the Australian average for the selling State.',
                },
                color: {
                    label: 'Colour',
                    value: formatSingleAmount(color, currency),
                    color: detectColor(color),
                    tooltip: 'The price adjustment for the car based on the colour of the vehicle',
                },
            });
        } else {
            setPriceGuideResponse(false);
        }
        setPrices(await api.getAUPriceForEachState(vehicleKey, km));
    };

    const renderPriceGuideEntries = () => {
        return Object.keys(priceGuideResponse).map((key) => {
            const entry = priceGuideResponse[key];
            return (
                <>
                    <div
                        key={`price-guide-entry-${key}`}
                        className={`w-full flex flex-row font-light ${entry.additionalSyles || ''}`}
                    >
                        <span className="text-md text-gray-500">{entry.label}:</span>
                        {entry.label === 'Trade Price' &&
                            (breakDownRevealed ? (
                                <MinusCircleIcon
                                    onClick={() => setBreakDownRevealed(false)}
                                    className="w-5 ml-2 cursor-pointer text-blue-400 hover:text-blue-600"
                                />
                            ) : (
                                <PlusCircleIcon
                                    onClick={() => setBreakDownRevealed(true)}
                                    className="w-5 ml-2 cursor-pointer text-blue-400 hover:text-blue-600"
                                />
                            ))}

                        <span className="mt-1 flex-1 text-md text-gray-900 sm:mt-0 text-right">
                            <span className={`${entry.value === 'N/A' ? '' : entry.color}-label`}> {entry.value} </span>
                        </span>
                        {entry.label === 'Trade Price' && (
                            <div className="ml-2">
                                <Tooltip>
                                    <p>{entry.tooltip}</p>
                                </Tooltip>
                            </div>
                        )}
                    </div>
                    {entry.label === 'Trade Price' && (
                        <div
                            style={{
                                height: breakDownRevealed ? '7rem' : '0',
                            }}
                            className="tradePriceBreakdownTable relative"
                        >
                            {breakDownRevealed && renderTradePriceBreakdown()}
                        </div>
                    )}
                </>
            );
        });
    };

    const renderTradePriceBreakdown = () => {
        return Object.keys(tradePriceBreakdown).map((key) => {
            const entry = tradePriceBreakdown[key];
            return (
                <div
                    key={`trade-price-breakdown-${key}`}
                    className={`w-full flex flex-row font-light items-center tradePriceBreakdown ${
                        entry.additionalSyles || ''
                    }`}
                >
                    <div className="text-md w-5/12 text-gray-500">{entry.label}:</div>
                    {entry.label === 'Mileage' && (
                        <div className={`text-sm w-5/12 ${entry.color}-label`}>({avgLevel})</div>
                    )}
                    {entry.label === 'State' && <div className={`text-sm w-5/12 text-gray-500`}>({user.state})</div>}
                    {entry.label === 'Colour' && carObject.extColor && (
                        <div className={`text-sm w-5/12 text-gray-500`}>({carObject.extColor})</div>
                    )}
                    <div className="mt-1 w-7/12 text-md text-gray-900 sm:mt-0 text-right">
                        <div className={`${entry.value === 'N/A' ? '' : entry.color}-label`}> {entry.value} </div>
                    </div>
                    <div className="ml-2">
                        <Tooltip>
                            <p>{entry.tooltip}</p>
                        </Tooltip>
                    </div>
                </div>
            );
        });
    };

    return (
        <div className="flex flex-col md:grid grid-cols-3 gap-4">
            <div className="col-span-3">
                <CardComponent title={carObject.label}>
                    <div className="grid grid-cols-1">
                        <div className="col-span-1">
                            <div className="text-sm text-gray-500 flex items-center">
                                <div>
                                    <strong>Mileage: </strong>{' '}
                                    {!!km && parseInt(km) >= 0 ? `${formatNumber(km)} kilometers` : `No Input`}{' '}
                                </div>
                                {carObject.extColor && (
                                    <div className="flex items-center gap-1">
                                        <span className="ml-4 ">
                                            <strong>Ext. Colour:</strong>{' '}
                                            <span className="capitalize">{carObject.extColor}</span>
                                        </span>
                                        {isValidColor(carObject.extColor) && (
                                            <div
                                                className="rounded border"
                                                style={{
                                                    height: '1em',
                                                    width: '1em',
                                                    backgroundColor: carObject.extColor,
                                                }}
                                            />
                                        )}
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </CardComponent>
            </div>
            <div className="col-span-1 relative z-10">
                <CardComponent
                    loadingSkeletons="3"
                    skeletonsHeights={[30, 30, 30]}
                    title="Price Guide"
                    loading={!priceGuideResponse}
                    className=""
                >
                    <div className="text-sm text-gray-500 mb-2">
                        <div className="w-full flex flex-row">
                            <span className="text-sm w-5/12 font-medium text-gray-500">Average Mileage: </span>
                            <span className="mt-1 w-7/12 text-sm text-gray-900 sm:mt-0 text-right">
                                {' '}
                                <span>
                                    {' '}
                                    {formatRangeWithUnit(roundRange(avgMileage, 1000).split(' - '), 'KM', 0)}{' '}
                                </span>{' '}
                            </span>
                        </div>
                    </div>
                    <div className=""> {!!priceGuideResponse && renderPriceGuideEntries()} </div>
                </CardComponent>
            </div>
            <div className="col-span-2 row-span-3 relative z-0">
                <CardComponent title="Recent Appraised Cars" className="h-full">
                    <Scatter vehicleCode={vehicleCode} avgMileageRange={avgMileage} tradePriceRange={tradePriceRange} />
                </CardComponent>
            </div>
            <div className="col-span-1">
                <CardComponent className="" title="Average Price Per State">
                    <Map className="" vehicleCode={vehicleCode} km={km} priceList={priceList} />
                </CardComponent>
            </div>
            <div className="col-span-1">
                <CardComponent
                    loadingSkeletons="2"
                    skeletonsHeights={[30, 200]}
                    loading={!priceList}
                    className=""
                    title="Average Price Per State"
                >
                    <PricesPerStateTable priceList={priceList} />
                </CardComponent>
            </div>
        </div>
    );
};

export default PriceGuideResult;
