import React, { useState, useEffect } from 'react'
import { BeatLoader } from 'react-spinners';
import insightsApi from '../../api/insights';
import BrandInsight from './BrandInsight';
import BrandsComparison from './BrandsComparison';
import ModelInsight from './ModelInsight';

let brandsInsightsCache = {};

const BrandComparisonCard = ({ userBrands, brandSelected, setBrandSelected, selectionRange, dateApplied }) => {


    useEffect(() => {
        if ( !selectionRange ) return;
        getBrandComparison(selectionRange.startDate, selectionRange.endDate);
        setBrandSelected(null);
        setBrandInsightsData({});
        userBrands.forEach( brand => {
            getBrandInsights( brand.name, false );
        } );
    }, [dateApplied]);


    const [title, setTitle] = useState('Brands Comparison');
    const [subTitle, setSubtitle] = useState('Evaluations by brand.');
    const [loadingTxt, setLoadingTxt] = useState('Loading Brand Insights');

    // brands
    const [brandLoading, setBrandLoading] = useState(true);
    const [brandComparisonData, setBrandComparisonData] = useState();
    const [brandInsightsData, setBrandInsightsData] = useState({});
    const [modelInsightData, setModelInsightData] = useState();

    const getBrandComparison = async (startDate, endDate) => {
        setBrandComparisonData( null );
        setBrandLoading(true);
        const data = await insightsApi.getBrandComparison(startDate, endDate);
        setBrandLoading(false);
        setBrandComparisonData(data);
    };


    useEffect( () => {
        brandsInsightsCache = brandInsightsData;
    }, [ brandInsightsData ]);
    

    useEffect( () => {
        if ( !brandSelected ) {
            setTitle('Brands Comparison');
            setSubtitle('Evaluations by brand.');
            setLoadingTxt('Loading Brand Insights');
            return;
        }
        setTitle(`${brandSelected} Insights`);
        setSubtitle(`${brandSelected} evaluations broken down by model.`);
        setLoadingTxt(`Loading ${brandSelected} Insights`);
    }, [ brandSelected ] );

    const getBrandInsights = async (brand, setSelected) => {
        if ( !!setSelected ) {
            setBrandSelected(brand);
        }
        
        if ( brandInsightsData[brand] ) {
            return;
        }
        if ( setSelected ) setBrandLoading(true);
        const data = await insightsApi.getBrandInsights(brand, selectionRange.startDate, selectionRange.endDate);
        brandsInsightsCache = { ...brandsInsightsCache, [brand]: data };
        setBrandInsightsData( brandsInsightsCache );
        if ( setSelected ) setBrandLoading(false);
    };

    const getModelInsights = async (brand, model) => {
        setBrandLoading(true);
        setTitle(`${brand} ${model} Insights`);
        setSubtitle(`${model} evaluations broken down by variant.`);
        setLoadingTxt(`Loading ${brand} ${model} Insights`);

        const data = await insightsApi.getModelInsights(brand, model, selectionRange.startDate, selectionRange.endDate);

        setModelInsightData(data);
        setBrandLoading(false);
    };


    const onBackBtnPressed = () => {

        if (modelInsightData && modelInsightData.length) {
            setModelInsightData(null);
            setTitle(`${brandSelected} Insights`);
            setSubtitle(`${brandSelected} evaluations broken down by model.`);
        } else if (brandSelected) {
            setBrandSelected(null);
            setTitle(`Brands Comparison`);
            setSubtitle(`Evaluations by brand.`);
        }

    }

    return (
        <div className="bg-white shadow rounded-lg divide-y divide-gray-200">
                <div className="pt-1">
                    <div className="bg-white py-5 border-b border-gray-200 sm:px-6">
                        <div className="-ml-4 -mt-4 flex items-center flex-wrap sm:flex-nowrap">
                            <div className="ml-4 mt-4 flex-shrink-0">
                                { ( modelInsightData || brandSelected ) && <button onClick={onBackBtnPressed} disabled={ brandLoading ? "disabled": undefined } type="button" className="text-xl font-bold rounded bg-white">&#8592;</button> }
                            </div>
                            <div className="ml-4 mt-4">
                                <h3 className="text-lg leading-6 font-medium text-gray-900">{title}</h3>
                                <p className="mt-1 text-sm text-gray-500">
                                    {subTitle}
                                </p>
                            </div>
                            
                        </div>
                    </div>

                    <div className="py-4 px-4 h-5/6">
                        { !brandComparisonData && !brandLoading && (
                            <div style={{height: '100px'}}>
                                <div className="bg-orange-100 border-l-4 border-orange-500 text-orange-700 p-5 my-5" role="alert" >No data found during this selected period.</div>
                            </div>
                        ) }
                        {brandComparisonData && !brandLoading && !brandSelected && (
                            <BrandsComparison
                                userBrands={userBrands}
                                brandComparisonData={brandComparisonData}
                                getBrandInsights={(brand) => { getBrandInsights(brand, true) } }
                            />
                        )}

                        {brandInsightsData && !brandLoading && !modelInsightData && brandInsightsData[brandSelected] &&  (
                            <BrandInsight
                                brandInsightsData={brandInsightsData[brandSelected]}
                                brand={brandSelected}
                                getModelInsights={getModelInsights}
                            />
                        )}
                        {modelInsightData && !brandLoading && <ModelInsight modelInsightData={modelInsightData} />}

                        {brandLoading && (
                            <div className="flex flex-col items-center justify-center" style={{minHeight: '400px'}}>
                                <BeatLoader className="block" color={'#60A5FA'} />
                                    <p className="text-md text-gray-500 pt-4">{loadingTxt}</p>
                            </div>
                        )}
                    </div>
                </div>
            </div>
    )
}

export default BrandComparisonCard
