import { firestore, realtime } from '../firebase.config';
import { collection, query, where, getDocs, orderBy, limit } from 'firebase/firestore';
import axiosInstance from '../custom-axios';



const getAUPrice = async (redbookCode, km, state = null) => {
    const formData = new FormData();
    formData.append('redbookCode', redbookCode);
    formData.append('km', km);
    formData.append('state', state);
    formData.append('explainability', 1);

    const response = await axiosInstance.post('/auPriceGuide', formData);

    return response.data;
};

const getAUPriceForEachState = async (redbookCode, km) => {
    const states = ['QLD', 'NSW', 'ACT', 'TAS', 'SA', 'WA', 'NT', 'VIC'];
    const pricesTemp = [];

    await Promise.all(
        states.map((state) => {
            return new Promise(async (resolve) => {
                const formData = new FormData();
                formData.append('redbookCode', redbookCode);
                formData.append('km', km);
                formData.append('state', state);

                const response = await axiosInstance.post('/auPriceGuide', formData);
                pricesTemp.push({ id: 'AU-' + state, name: state, value: response.data.value });

                resolve(true);
            });
        }),
    );

    return pricesTemp;
};

const getVehicleDetails = async (vin, state = null) => {
    const formData = new FormData();
    formData.append('vin', vin);
    if (state) {
        formData.append('state', state);
    }

    const response = await axiosInstance.post('/vindetails', formData);

    return response.data;
};

const getPricesForSimilarEvalsAU = async (redbookCode, days) => {
    const plotArray = [];
    await Promise.all(
        [0, 1].map((param) => {
            return new Promise(async (resolve) => {
                const formData = new FormData();
                formData.append('redbookCode', redbookCode);
                formData.append('days', days);
                formData.append('onlyIdentical', param);

                const response = await axiosInstance.post('/auInsights/getPricesForSimilarEvalsAU', formData);
                plotArray[param] = response.data;
                resolve(true);
            });
        }),
    );

    return plotArray;
};

const getMakes = (country) => {
    let path = 'Dropdowns/' + country + '/Makes';
    const querySnapshot = getDocs(collection(firestore, path));

    return querySnapshot;
};

const getModels = (country, year, make) => {
    let path = 'Dropdowns/' + country + '/Data';

    const modelsRef = collection(firestore, path);
    const q = query(modelsRef, where('Make', '==', make), where('Year', '==', year.toString()));

    return getDocs(q);
};

const getVariants = (country, year, make, model) => {
    let path = 'Dropdowns/' + country + '/Data';

    const variantRef = collection(firestore, path);
    const q = query(
        variantRef,
        where('Make', '==', make),
        where('Model', '==', model),
        where('Year', '==', year.toString()),
    );

    return getDocs(q);
};

const getTransmissions = (country, year, make, model, variant) => {
    let path = 'Dropdowns/' + country + '/Data';

    const modelsRef = collection(firestore, path);
    const q = query(
        modelsRef,
        where('Variant', '==', variant),
        where('Make', '==', make),
        where('Model', '==', model),
        where('Year', '==', year.toString()),
    );

    return getDocs(q);
};

const getFinalOptions = (country, year, make, model, variant, transmission, body, litre) => {
    let path = 'Dropdowns/' + country + '/Data';
    const dataRef = collection(firestore, path);

    let q;
    if (year && make && model && variant && transmission) {
        q = query(
            dataRef,
            where('Transmission', '==', transmission),
            where('Variant', '==', variant),
            where('Make', '==', make),
            where('Model', '==', model),
            where('Year', '==', year.toString()),
        );
    }
    if (body && transmission) {
        q = query(
            dataRef,
            where('Body', '==', body),
            where('Transmission', '==', transmission),
            where('Variant', '==', variant),
            where('Make', '==', make),
            where('Model', '==', model),
            where('Year', '==', year.toString()),
        );
    }

    if (body && !transmission) {
        q = query(
            dataRef,
            where('Body', '==', body),
            where('Variant', '==', variant),
            where('Make', '==', make),
            where('Model', '==', model),
            where('Year', '==', year.toString()),
        );
    }

    if (litre && transmission && body) {
        q = query(
            dataRef,
            where('Variant', '==', variant),
            where('Transmission', '==', transmission),
            where('Litres', '==', litre),
            where('Body', '==', body),
            where('Make', '==', make),
            where('Model', '==', model),
            where('Year', '==', year.toString()),
        );
    }
    if (litre && transmission && !body) {
        q = query(
            dataRef,
            where('Variant', '==', variant),
            where('Transmission', '==', transmission),
            where('Litres', '==', litre),
            where('Make', '==', make),
            where('Model', '==', model),
            where('Year', '==', year.toString()),
        );
    }
    if (litre && !transmission) {
        q = query(
            dataRef,
            where('Variant', '==', variant),
            where('Litres', '==', litre),
            where('Make', '==', make),
            where('Model', '==', model),
            where('Year', '==', year.toString()),
        );
    }

    return getDocs(q);
};

export default {
    getMakes,
    getModels,
    getVariants,
    getTransmissions,
    getFinalOptions,
    getAUPrice,
    getVehicleDetails,
    getPricesForSimilarEvalsAU,
    getAUPriceForEachState,
};
