
import axiosInstance from '../custom-axios';


const formatForQuery = (date) => {
    if (!date) return date;

    let formattedDate = date.toISOString().slice(0, 19).replace('T', ' ');

    return formattedDate;
};

const getInsightsChartData = async (startDate, endDate, brands) => {
        const brandsArr = brands.split(",");

     let data ={};
     await Promise.all(brandsArr.map(brand => {
         return   axiosInstance.get('/auInsights/getInsightsChartData', {
            params: {
                startDate: formatForQuery(startDate),
                endDate: formatForQuery(endDate),
                brands: brand,
            },
        }).then(res => {
            data = {...data,  [brand]:res.data[brand]}
        });
     }));
    return data;
};

const getBrandComparison = async (startDate, endDate) => {
    const response = await axiosInstance.get('/auInsights/getBrandComparison', {
        params: {
            startDate: formatForQuery(startDate),
            endDate: formatForQuery(endDate),
        },
    });

    return response.data;
};

const getBrandInsights = async (brand, startDate, endDate) => {
    const response = await axiosInstance.get('/auInsights/getBrandInsights', {
        params: {
            brand,
            startDate: formatForQuery(startDate),
            endDate: formatForQuery(endDate),
        },
    });
    return response.data;
};

const getModelInsights = async (brand, model, startDate, endDate) => {
    const response = await axiosInstance.get('/auInsights/getModelInsights', {
        params: {
            brand,
            model,
            startDate: formatForQuery(startDate),
            endDate: formatForQuery(endDate),
        },
    });
    return response.data;
};
export default {
    getInsightsChartData,
    getBrandComparison,
    getBrandInsights,
    getModelInsights,
};
