import React, { useContext, useEffect, useState } from 'react';
import insightsApi from '../../api/insights';
import InsightsResult from './InsightsResult';
import DatePickerInput from './DatePickerInput';
import BrandComparisonCard from './BrandComparisonCard';
import { UserContext } from '../../contexts/userContext';
import { XYChartLoaderComponent } from "../Loaders/XYChartLoader/XYChartLoader";

const Insights = () => {

    const { user } = useContext(UserContext);
    
    const [userBrands, setUserBrands] = useState(user.dealerFranchises);
    const [dateApplied, setDateApplied] = useState(0);
    const [brandSelected, setBrandSelected] = useState();

    // charts
    const [chartData, setChartData] = useState([]);
    const [chartDataCompare, setChartDataCompare] = useState([]);
    const [chartLoading, setChartloading] = useState(false);

    // set dates
    const today = new Date();
    today.setDate(today.getDate() - 365); // default is 30 days
    const [selectionRange, setSelectionRange] = useState({
        startDate: today,
        endDate: new Date(),
        key: 'selection',
    });

    useEffect(() => {
        getInsightsChartData(selectionRange.startDate, selectionRange.endDate, userBrands);
    }, []);

    const getInsightsChartData = async (startDate, endDate, brands) => {
        setChartloading(true);

        const data = [];
        const mappedResponse = await insightsApi.getInsightsChartData(startDate, endDate, brands.map( brand => brand.name.toLowerCase() ).concat(['all']).join(','));
        Object.keys( mappedResponse ).forEach( brand => { if ( brand !== 'all' ) data.push( mappedResponse[brand] ) } );
        data.push(mappedResponse['all']);
        // if we have a brand, set the data to compare
        if (Array.isArray( brands ) && brands.length > 0 ) {
            setChartData(data);
            setChartloading(false);
        }
    };

    const onDateRangeApply = () => {

        setDateApplied(dateApplied + 1);
        getInsightsChartData(selectionRange.startDate, selectionRange.endDate, userBrands);
    };

    return (
        <>
            <div className="bg-white shadow rounded-lg divide-y divide-gray-200 mb-4">
                <div className="pt-1">
                        <div className="bg-white py-5 border-b border-gray-200 sm:px-6">
                            <div className="-ml-4 -mt-4 flex justify-between items-center flex-wrap sm:flex-nowrap">
                                <div className="ml-4 mt-4">
                                    <h3 className="text-lg leading-6 font-medium text-gray-900">Evals Changes Over Time</h3>
                                    <p className="mt-1 text-sm text-gray-500">
                                        Percentage increase/decrease of evaluations completed over time.
                                    </p>
                                </div>
                                <div className="ml-4 mt-4 flex-shrink-0">
                                    <DatePickerInput
                                        userBrands={userBrands}
                                        selectionRange={selectionRange}
                                        setSelectionRange={setSelectionRange}
                                        onDateRangeApply={onDateRangeApply}
                                    />
                                </div>
                            </div>
                        </div>
                <div className="px-4 py-5 sm:p-6">
                        
                    {!chartLoading && (
                        // TODO: refactor name
                        <InsightsResult
                            userBrands={userBrands}
                            chartData={chartData}
                            brands={userBrands}
                        />
                    )}
                    {chartLoading && (
                        <XYChartLoaderComponent height="450px" className="w-full" chartId="evalsPercentageLoader" chartType="line"></XYChartLoaderComponent>
                    )}
                </div>
                </div>
            </div>

            <BrandComparisonCard 
                userBrands={userBrands}
                selectionRange={selectionRange}
                setBrandSelected={setBrandSelected}
                brandSelected={brandSelected}
                dateApplied={dateApplied}
            />
        </>
    );
};

export default Insights;
