import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getAuth, signInAnonymously } from "firebase/auth";
import { getDatabase } from "firebase/database";

const firebaseConfig = {
    apiKey: "AIzaSyDoNGOZvuRekysj9BZviOHCna6EXgtV-70",
    authDomain: "algodriven-fb01.firebaseapp.com",
    databaseURL: "https://algodriven-fb01.firebaseio.com",
    projectId: "algodriven-fb01",
    storageBucket: "algodriven-fb01.appspot.com",
    messagingSenderId: "733238792714",
    appId: "1:733238792714:web:22e804ad8203aab1d1c5f3"
};


const app = initializeApp(firebaseConfig);
const firestore = getFirestore(app);
const realtime = getDatabase();

const auth = getAuth();
signInAnonymously(auth)
  .then(() => {
    // Signed in..
  })
  .catch((error) => {
    const errorCode = error.code;
    const errorMessage = error.message;
    // ...
  });
export {
  firestore,
  realtime
};
