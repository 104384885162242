import React, { useState } from 'react'
import { CalendarIcon } from '@heroicons/react/outline'
import DatePickerModal from './DatePickerModal'

const DatePickerInput = ({ selectionRange, setSelectionRange, onDateRangeApply }) => {

    const [open, setOpen] = useState(false);

    return (
        <div>
            <DatePickerModal open={open} setOpen={setOpen} selectionRange={selectionRange} setSelectionRange={setSelectionRange} onDateRangeApply={onDateRangeApply} />

            <div className="relative rounded-md shadow-sm w-60" onClick={() => setOpen(true)}>
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center cursor-pointer">
                    <CalendarIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                </div>
                <input
                    type="email"
                    name="email"
                    id="email"
                    disabled={true}
                    className="focus:ring-primary-500 focus:border-primary-500 block w-full pl-10 sm:text-sm border-gray-300 rounded-md cursor-pointer"
                    placeholder={selectionRange.startDate.toLocaleDateString("en-AU") + ' - ' + selectionRange.endDate.toLocaleDateString("en-AU")}
                />
            </div>
        </div>
    )
}

export default DatePickerInput
