import './App.scss';
import { HashRouter as Router, Switch, Route, Redirect, useLocation } from 'react-router-dom';

import { useEffect, useState } from 'react';
import { ENV_NAME } from './env';
import Layout from './Layout';
import PriceGuide from './components/PriceGuide/PriceGuide';
import PriceGuideResult from './components/PriceGuide/PriceGuideResult';
import Insights from './components/Insights/Insights';
import LoginPage from './pages/login/login';
import ForgotPasswordPage from './pages/forgotPassword/forgotPassword';
import { UserService } from './services/userService';

import { UserContext } from './contexts/userContext';
import { PageContext } from './contexts/pageContext';
import { AutoLogin } from './pages/autologin/AutoLogin';
import { PriceGuideSubmitContext } from './contexts/priceGuideSubmit';

function App({ history }) {
    const [user, setUser] = useState();
    const [page, setPage] = useState();

    const userService = UserService.getInstance();
    userService.setUserContext = setUser;

    const [priceGuideSubmit, setPriceGuideSubmit] = useState( false );

    const [loaded, setLoaded] = useState(false);
    useEffect(() => {
        userService.loadUser();
        setLoaded(true);
    }, []);

    return (
        <UserContext.Provider value={{ user, setUser }}>
            <PageContext.Provider value={{ page, setPage }}>
                <PriceGuideSubmitContext.Provider value={{ priceGuideSubmit, setPriceGuideSubmit}}>                
                    {loaded && !user && (
                        <Router>
                            <Switch>
                                <Route exact  path="/">
                                <Redirect to={"/login"} ></Redirect>
                                </Route>

                                <Route path="/login"> 
                            
                                    <LoginPage />
                                </Route>

                                <Route path="/forgot-password">
                                    <ForgotPasswordPage />
                                </Route>
                                <Route path="/autologin/:token">
                                    <AutoLogin></AutoLogin>
                                
                                </Route>
                                <Route>
                                <Redirect to={"/login"} ></Redirect>

                                </Route>
                            </Switch>
                        </Router>
                    )}

                    {loaded && user && (
                        <Router>
                            <Layout>
                                <Switch>
                                    <Route exact path="/price-guide">
                                        <PriceGuide />
                                    </Route>
                                    <Route path="/insights">
                                        <Insights />
                                    </Route>
                                    <Route path="/price-guide/:data">
                                        <PriceGuideResult />
                                    </Route>
                                    <Route>
                                        <Redirect to="/price-guide" />
                                    </Route>
                                </Switch>
                            </Layout>
                        </Router>
                    )}
                </PriceGuideSubmitContext.Provider>
            </PageContext.Provider>
        </UserContext.Provider>
    );
}

export default App;
