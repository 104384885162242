import React, { useLayoutEffect, useRef } from "react";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4maps from "@amcharts/amcharts4/maps";
import am4geodata_australiaLow from "@amcharts/amcharts4-geodata/australiaLow";

am4core.options.autoSetClassName = true;


export function MapChartLoaderComponent(props) {
    const chartRef = useRef(null);
    const geoMaps = [
        { region: 'australia', amchartsMap: am4geodata_australiaLow },
    ];

    useLayoutEffect( () => {
        let map = am4core.create( props.chartId , am4maps.MapChart);
    
        map.seriesContainer.draggable = false;
        map.chartContainer.wheelable = false;
        map.maxZoomLevel = 1;
    
        map.geodata = geoMaps.filter( map => map.region === props.region )[0] || am4geodata_australiaLow;
    
        map.projection = new am4maps.projections.Miller();
    
        let polygonSeries = map.series.push(new am4maps.MapPolygonSeries());
    
        polygonSeries.useGeodata = true;
    
        polygonSeries.heatRules.push({
          property: "fill",
          target: polygonSeries.mapPolygons.template,
          min: am4core.color("#90e0ef"),
          max: am4core.color("#0077b6"),
          logarithmic: true,
        });
    
        let polygonTemplate = polygonSeries.mapPolygons.template;
        polygonTemplate.tooltipText = "{name}: ${value}";
        polygonSeries.data = Array.from({length: 40}, () => Math.floor(Math.random() * 40));;

        chartRef.current = map;

        return () => {
            map.dispose();
        };
    }, []);

    return (
        <div id={ props.chartId } className={ `bg-grey-900 text-grey-900 ${props.className} with-loader` } style={{ height: props.height || '400px' }}></div>
    );
}
