import React, { useContext, useEffect, useState } from "react";
import CarPicker from "./CarPicker";
import { useHistory } from "react-router-dom";
import api from "../../api/priceGuide";
import { PriceGuideSubmitContext } from "../../contexts/priceGuideSubmit";
import {ExclamationCircleIcon, XCircleIcon} from '@heroicons/react/solid';


const PriceGuide = () => {

  const [loading, setLoading] = useState(false);
  const [km, setKm] = useState();
  const [carObject, setCarObject] = useState();
  const [vehicleSelectionMode, setVehicleSelectionMode] = useState("registration");
  const { priceGuideSubmit, setPriceGuideSubmit } = useContext(PriceGuideSubmitContext);
  const [error, setError] = useState(null);

  useEffect( () => {
    if ( !!priceGuideSubmit && !loading ) getPriceGuide();
    setPriceGuideSubmit(false);
  }, [ priceGuideSubmit ] );

  const history = useHistory();

  const getVehicleDetails = async (vin, state = null) => {
    let res = await api.getVehicleDetails(vin, state);
    return res;
  }

  const clearFilter = () => {
    setCarObject( {} );
    setKm( '' );
  }

  const getPriceGuide = async () => {

    const {id, label} = carObject;

    let res;
    let carData = { id, label };

    if (vehicleSelectionMode === 'vin') {
        setLoading(true);
        res = await getVehicleDetails(carObject.vin);
        if(res.error) {
          setError(res.error);
          setLoading(false);
          return;
        }
        carData = { id: res[0]?.redbookCode, label: res[0]?.Description, extColor: res[0]?.Ext_Colour};
    }

    if (vehicleSelectionMode === 'registration') {
      setLoading(true);
      res = await getVehicleDetails( carObject.reg, carObject.province.value );
      if(res[0]?.error) {
        setError(res[0]?.error);
        setLoading(false);
        return;
      }
      carData = { id: res[0]?.redbookCode, label: res[0]?.Description, extColor: res[0]?.Ext_Colour };
    }
  
    setLoading(false);
    const data = {
      carData,
      km,
    };

    const payload = btoa(JSON.stringify(data));
    history.push("/price-guide/" + payload);

  }

  const handleUserKeyPress = (event) => {
      if ( event.key !== 'Enter' || !carObject || !(km >= 0 && !isNaN(km)) ) return;
      getPriceGuide();
  };


  return (
    <div className="bg-white shadow rounded-lg divide-y divide-gray-200">
      <div className="px-4 py-5 sm:p-6">
        <CarPicker
          setCarObject={setCarObject}
          setVehicleSelectionMode={setVehicleSelectionMode}
          vehicleSelectionMode={vehicleSelectionMode}
          loading={loading}
        />
        <div className="flex flex-row md:flex-row md:mx-10 flex-wrap justify-items-end">
            <div className="md:w-1/3 mb-4 justify-self-start">
              <label htmlFor="Mileage" className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2 ">
                Mileage (km)
              </label>
              <div className="mt-1">
                <input type="text" value={km} onChange={ (e) => setKm(e.target.value) } onKeyUp={ (e) => handleUserKeyPress(e) }
                  className="custom-input shadow-sm focus:ring-primary-500 focus:border-primary-500 block sm:text-sm border-gray-300 rounded-md"/>
              </div>
            </div>
            <div className="mt-2 md:w-2/3 flex justify-end items-center">
                <div>
                  <button
                    onClick={clearFilter}
                    type="button" className="block flex-grow bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500">
                    Clear Filter
                  </button>
                </div>
                { !loading && <div>
                  <button  disabled={!carObject } onClick={getPriceGuide} type="submit" 
                    className="block flex-grow ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-primary-600 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500">
                    Submit
                  </button>
                </div> }
                { loading && <div>
                  <button type="button" className="block flex-grow ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-primary-600 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 text-white transition ease-in-out duration-150" disabled="disabled">
                    <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                      <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                      <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                    </svg>
                    Processing...
                  </button>
                </div> }
            </div>
        </div>
        {error && (
          <div className="bg-red-200 p-5 rounded-md mt-4">
            <XCircleIcon onClick={() => setError(null)} className="text-red-800 w-5 float-right cursor-pointer hover:text-red-500 "/>
            <div className="text-red-500 flex flex-col gap-2 md:flex-row items-center justify-start mt-2">
              <ExclamationCircleIcon className="w-10 md:w-5"/> <span className="text-md">{error}</span>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default PriceGuide;
