import './login.scss';
import logo from '../../assets/logo.svg';
import { useEffect, useState } from 'react';
import { UserService } from '../../services/userService';

import { XCircleIcon } from '@heroicons/react/solid';
import { useHistory } from 'react-router-dom';
import { ENV } from '../../env';

function LoginPage() {
    if(ENV  === "production") {
        window.location.href="https://algodriven.app/insightsdashboard/pages/dashboard/index.php";

    }

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);

    const userService = UserService.getInstance();
    const history = useHistory();
    
    const submit = async () => {
        if (!email || !password || loading) {
            return;
        }
        setError('');
        setLoading(true);
        try {
            await userService.login(email, password);
            setLoading(false);
        } catch (e) {
            console.error('ERROR 4', e);
            setError(e);
            setLoading(false);
        }
    };
    return (
        <div className={`min-h-screen bg-gray-50 flex flex-col justify-center  sm:px-6 lg:px-8 lg:py-12  ${ENV === "production" ? "hidden" : "" }` }>
            
            <div className="sm:mx-auto sm:w-full sm:max-w-md">
                <img className="mx-auto h-8 w-auto" src={logo} alt="Workflow" />
                <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">Sign in to Insights</h2>
            </div>

            <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
                <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
                    <div className="space-y-6">
                        <div>
                            <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                                Email address
                            </label>
                            <div className="mt-1">
                                <input
                                    id="email"
                                    name="email"
                                    type="email"
                                    autoComplete="email"
                                    required
                                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                                    onChange={(ev) => {
                                        setEmail(ev.target.value);
                                    }}
                                    onKeyUp={(ev) => {
                                        if (ev.key === 'Enter') {
                                            submit();
                                        }
                                    }}
                                />
                            </div>
                        </div>

                        <div>
                            <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                                Password
                            </label>
                            <div className="mt-1">
                                <input
                                    id="password"
                                    name="password"
                                    type="password"
                                    autoComplete="current-password"
                                    required
                                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                                    onChange={(ev) => {
                                        setPassword(ev.target.value);
                                    }}
                                    onKeyUp={(ev) => {
                                        if (ev.key === 'Enter') {
                                            submit();
                                        }
                                    }}
                                />
                            </div>
                        </div>

                        <div className="flex items-center justify-end">
                            <div className="text-sm">
                                <span
                                    onClick={() => {
                                        history.push('/forgot-password');
                                    }}
                                    className="font-medium text-blue-600 hover:text-blue-500 clickable"
                                >
                                    Forgot your password?
                                </span>
                            </div>
                        </div>

                        <div>
                            <button
                                // type="submit"
                                className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:opacity-75"
                                disabled={!email || !password || loading ? true : undefined}
                                onClick={() => {
                                    submit();
                                }}
                            >
                                {loading && (
                                    <svg
                                        className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                    >
                                        <circle
                                            className="opacity-25"
                                            cx="12"
                                            cy="12"
                                            r="10"
                                            stroke="currentColor"
                                            strokeWidth="4"
                                        ></circle>
                                        <path
                                            className="opacity-75"
                                            fill="currentColor"
                                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                        ></path>
                                    </svg>
                                )}
                                Sign in
                            </button>
                        </div>
                    </div>
                </div>

                <div className="error-wrapper">
                    {error && (
                        <div className="rounded-md bg-red-50 p-4 flex justify-between  error">
                            <div className="flex">
                                <div className="flex-shrink-0">
                                    <XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
                                </div>
                                <div className="ml-3">
                                    <h3 className="text-sm font-medium text-red-800">{error}</h3>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}

export default LoginPage;
