import React, { useEffect, useState } from 'react';
import { ExclamationCircleIcon } from '@heroicons/react/outline';

const Tooltip = ({ children }) => {
    const [showTooltip, setShowTooltip] = useState(false);

    return (
        <div className="relative" onMouseEnter={() => setShowTooltip(true)} onMouseLeave={() => setShowTooltip(false)}>
            <div className="cursor-pointer ml-auto">
                <ExclamationCircleIcon className="text-gray-400 hover:text-gray-600 w-4 h-4 font-bold" />
            </div>

            {showTooltip && (
                <>
                    <div
                        role="tooltip"
                        style={{ zIndex: '1000', top: '-25px' }}
                        className="w-64 absolute tooltip transition duration-150 ease-in-out left-0 ml-8 shadow-lg bg-gray-800 p-4 rounded"
                    >
                        <div className="text-white">{children}</div>
                    </div>
                </>
            )}
        </div>
    );
};

export default Tooltip;
