import { useEffect } from "react";
import { useHistory, useParams } from "react-router";
import { UserService } from "../../services/userService";

import BeatLoader from 'react-spinners/BeatLoader';

const userService = UserService.getInstance();

export function AutoLogin() {
    const {token} = useParams();
    const history = useHistory();
    
     const processToken = async() => {
        try {
            if(!token) {
                throw new Error('token not found')
            }
            await userService.autoLogin(token);

        }
        catch(e) {
            history.replace('/');
        }
    }
    useEffect(() => {
        processToken();
    },[]);
    return (
        <div className="w-screen h-screen flex justify-center items-center">
        <BeatLoader color={'#60A5FA'}></BeatLoader>

        </div>
    );
}