// import { BACKEND_URL } from '../env';

import { HttpService } from './httpService';
// TODO: change this
const BACKEND_URL  = 'https://evalexpert.io/v1';

export class UserService {
    static instance;
    firebaseToken;
    backendToken;
    setUserContext;

    static getInstance() {
        if (!this.instance) {
            this.instance = new UserService();
        }

        return this.instance;
    }

    async login(email, password) {
        const formData = new FormData();
        formData.append('email', email);
        formData.append('password', password);
        let response;
        console.log(BACKEND_URL + '/login');
        try {
            response = await HttpService.post(BACKEND_URL + '/login', formData, undefined, {
                'Content-Type': 'x-www-form-urlencoded',
            });
        } catch (e) {
            if (e.response.data) {
                throw e.response.data;
            }

            console.error(e);
            throw 'Something Went Wrong, Please try again later';
        }

        if (!response.data.id) {
            throw response.data;
        }

        sessionStorage.setItem('user', JSON.stringify(response.data));
        this.firebaseToken = response.data.jwt;
        this.backendToken = response.data.api_key;

        this.setUserContext(response.data);
    }

    logout() {
        this.firebaseToken = undefined;
        this.backendToken = undefined;
        sessionStorage.removeItem('user');
        this.setUserContext(undefined);
    }

    loadUser() {
        const storedUser = sessionStorage.getItem('user');
        if (storedUser) {
            const userJSON = JSON.parse(storedUser);
            this.firebaseToken = userJSON.jwt;
            this.backendToken = userJSON.api_key;

            this.setUserContext(userJSON);
        }
    }

    updateUser(key, value) {
        const storedUser = sessionStorage.getItem('user');
        const user = JSON.parse(storedUser);
        let currentObject = user;
        const keysArray = key.split('.');
        keysArray.forEach((keyItem, index) => {
            if (index === keysArray.length - 1) {
                currentObject[keyItem] = value;
            } else {
                currentObject = user[keyItem];
            }
        });
        this.setUserContext(user);
        sessionStorage.setItem('user', JSON.stringify(user));
    }

    async forgoPassword(email) {
        const formData = new FormData();
        formData.append('email', email);
        let response;
        try {
            response = await HttpService.post(BACKEND_URL + '/forgotpass', formData, undefined, {
                'Content-Type': 'x-www-form-urlencoded',
            });
        } catch (e) {
            if (e.response && e.response.data) {
                throw e.response.data;
            }

            console.error(e);
            throw 'Something Went Wrong, Please try again later';
        }

        if (!response || !response.data) {
            throw 'Something Went Wrong, Please try again later';
        }
    }

    async autoLogin(token) {
        const formData = new FormData();
        formData.append('token', token);
        let response;
        try {
            response = await HttpService.post(BACKEND_URL + '/auto-login/login', formData, undefined, {
                'Content-Type': 'x-www-form-urlencoded',
            });
            const user = response.data;
            this.backendToken = response.data.api_key;
            this.setUserContext(user);
            sessionStorage.setItem('user', JSON.stringify(user));

        } catch (e) {
            if (e.response && e.response.data) {
                throw e.response.data;
            }

            console.error(e);
            throw 'Something Went Wrong, Please try again later';
        }

        if (!response || !response.data) {
            throw 'Something Went Wrong, Please try again later';
        }
    }

    updateUser( user ) {
        if ( !!user ) {
            sessionStorage.setItem('user', JSON.stringify(user));
            this.firebaseToken = user.jwt;
            this.backendToken = user.api_key;

            this.setUserContext(user);
        } else {
            this.firebaseToken = undefined;
            this.backendToken = undefined;
            sessionStorage.removeItem('user');
            this.setUserContext(undefined);
        }
    }
}
