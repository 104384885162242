import { formatDecimalWithUnit, formatNumberWithUnit, formatWithCurrency } from "./numbersFormat";



export const getDataSource = ( dataMap, titleKey, dropFormatting = 0 ) => {
    return dataMap?.map(( entry, index ) => {
        return {
          key: index,
          title: entry[titleKey],
          percentageOfEvals: !!dropFormatting ? entry.percentageOfEvals : formatDecimalWithUnit( entry.percentageOfEvals, '%', 0 ),
          percentageOwnDealers: !!dropFormatting ?  entry.percentageOwnDealers : formatDecimalWithUnit( entry.percentageOwnDealers, '%', 0 ),
          averagePrice: !!dropFormatting ? entry.averagePrice : formatWithCurrency( entry.averagePrice, 'USD' ),
          averageAge: !!dropFormatting ? entry.averageAge : formatNumberWithUnit( entry.averageAge, 'Year(s)', 0 ),
          percentageTraded: !!dropFormatting ?  entry.percentageTraded : formatDecimalWithUnit( entry.percentageTraded, '%', 0 )
        }
    } );
}
