import React, { useState, useEffect, useContext } from 'react';
import Select from 'react-select';
import priceGuideApi from '../../api/priceGuide';
import { PriceGuideSubmitContext } from '../../contexts/priceGuideSubmit';

const ManualCarPicker = ({ setCarObject }) => {
    const [loading, setLoading] = useState(false);
    const [years, setYears] = useState([]);
    const [allMakes, setAllMakes] = useState([]);
    const [makes, setMakes] = useState([]);
    const [models, setModels] = useState([]);
    const [variants, setVariants] = useState([]);
    const [bodys, setBodys] = useState();
    const [transmissions, setTransmissions] = useState();
    const [litre, setLitre] = useState(); // todo: better names
    const [litres, setLitres] = useState();
    const [finalOptions, setFinalOptions] = useState([]);
    const [year, setYear] = useState();
    const [make, setMake] = useState();
    const [model, setModel] = useState();
    const [variant, setVariant] = useState();
    const [transmission, setTransmission] = useState();
    const [body, setBody] = useState();
    const [finalPick, setFinalPick] = useState(null);

    // hide show
    const [showBody, setShowBody] = useState(false);
    const [showTransmission, setShowTransmission] = useState(false);
    const [showLitres, setShowLitres] = useState(false);
    const [showPick, setShowPick] = useState(false);
    const { priceGuideSubmit, setPriceGuideSubmit } = useContext(PriceGuideSubmitContext);
    
    const handleUserKeyPress = (event) => {
        if ( event.key !== 'Enter' || !finalPick ) return;
        setCarObject(finalPick);
        setPriceGuideSubmit(true);
    };

    useEffect(() => {
        getMakes();
    }, []);

    useEffect(() => {
        if (make) {
            setModel(null);
            setVariant(null);
            setVariants([]);
            setShowLitres(false);
            setShowBody(false);
            setFinalOptions([]);
            setTransmission(null);
            setShowPick(false);
            setFinalPick(null);
            onMakeChange(make);
        }
    }, [year]);

    useEffect(() => {
        if (make) {
            setModel(null);
            setVariant(null);
            setBody(null);
            setTransmission(null);
            setVariants([]);
            setBodys([]);
            setShowLitres(false);
            setShowBody(false);
            setFinalOptions([]);
            setShowPick(false);
            setFinalPick(null);
        }
    }, [make]);

    useEffect(() => {
        if (finalOptions && finalOptions.length > 1) {
            setShowPick(false);
        }
        if (variant && transmission) {
            onTransmissionChange(transmission);
        }

        if (!variant) {
            setShowLitres(false);
        }
    }, [variant]);

    useEffect(() => {
        setBody(null);
        setShowTransmission(false);
    }, [model]);

    useEffect(() => {
    }, [finalOptions]);

    useEffect(() => {
        if (finalPick) {
            setCarObject(finalPick);
        }
    }, [finalPick]);

    const getMakes = async () => {
        setLoading(true);

        const years = [];
        const makes = [];
        const makesSnapshot = await priceGuideApi.getMakes('AU');
        makesSnapshot.forEach((doc) => {
            years.push({ value: doc.id, label: doc.id });
            makes.push({ year: doc.id, makes: doc.data() });
        });
        years.sort((a, b) => b.value - a.value);
        makes.sort((a, b) => b.label - a.label);
        setYears(years);
        setAllMakes(makes);
        setLoading(false);
    };

    const onYearChange = (year) => {
        setFinalPick(null);
        setYear(year);
        setLoading(true);
        let yearMakes = allMakes.filter((allMake) => allMake.year === year);
        yearMakes = yearMakes[0].makes;
        const makeOptions = Object.keys(yearMakes).map((makeKey) => {
            return { id: makeKey, label: yearMakes[makeKey] };
        });
        makeOptions.sort((a, b) => a.label.localeCompare(b.label));
        setMakes(makeOptions);
        setLoading(false);
    };

    const onMakeChange = async (make) => {
        setLoading(true);

        let modelsSnapshot = await priceGuideApi.getModels('AU', year, make.label);

        const modelsAdded = [];
        const modelOptions = [];
        modelsSnapshot.forEach((doc) => {
            const modelData = doc.data();
            if (!modelsAdded.includes(modelData.Model)) {
                modelOptions.push({
                    id: doc.id,
                    label: modelData.Model,
                    data: modelData,
                });
                modelsAdded.push(modelData.Model);
            }
        });
        modelOptions.sort((a, b) => a.label.localeCompare(b.label));

        setMake(make);
        setModels(modelOptions);
        setLoading(false);
    };

    const onModelChange = async (model) => {
        setLoading(true);
        let variantSnapshot = await priceGuideApi.getVariants('AU', year, make.label, model.label);

        const variantsAdded = [];
        const variantOptions = [];
        const bodysAdded = [];
        const bodyOptions = [];
        const litresAdded = [];
        const litresOptions = [];
        const finalVehicles = [];

        variantSnapshot.forEach((doc) => {
            const data = doc.data();
            if (!variantsAdded.includes(data.Variant)) {
                variantOptions.push({ id: doc.id, label: data.Variant, data: data });
                variantsAdded.push(data.Variant);
            }
            if (!litresAdded.includes(data.Litres)) {
                litresOptions.push({
                    id: doc.id,
                    label: data.Litres + 'L',
                    data: data,
                });
                litresAdded.push(data.Litres);
            }
            finalVehicles.push({ id: doc.id, label: data.Description, data: data });
            if (!bodysAdded.includes(data.Body)) {
                bodyOptions.push({ id: doc.id, label: data.Body, data: data });
                bodysAdded.push(data.Body);
            }
        });
        variantOptions.sort((a, b) => a.id.localeCompare(b.id));

        setLoading(false);
        setModel(model);
        setVariants(variantOptions);
        setLitres(null);
        setLitre(null);

        if (variantOptions.length === 1) {
            setVariant(variantOptions[0]);
            setShowPick(false);
        } else {
            setShowPick(false);
            setVariant(null);
        }
        // if there is only 1 vehicle after this search then we are done
        if (variantSnapshot.docs.length === 1) {
            setFinalOptions(finalVehicles);
            setFinalPick(finalVehicles[0]);
            setShowPick(true);
        } else if (variantOptions.length === 1 && litresOptions.length > 1) {
            setShowLitres(true);
        } else {
            setFinalPick(null);
            setFinalOptions(finalVehicles);
        }

        if (bodyOptions.length === 1) {
            setBody(bodyOptions[0]);
            setBodys(bodyOptions);
        } else {
            setBodys(bodyOptions);
            setShowBody(false);
        }

        if (litresOptions.length === 1) {
            setLitre(litresOptions[0]);
        } else {
            setLitres(litresOptions);
        }
    };

    const onVariantChange = async (variant) => {
        const bodysAdded = [];
        const bodyOptions = [];

        const transmissionAdded = [];
        const transmissionOptions = [];

        let transmissionSnapshot = await priceGuideApi.getTransmissions('AU', year, make.label, model.label, variant.label);
        transmissionSnapshot.forEach((doc) => {
            const data = doc.data();
            if (!transmissionAdded.includes(data.Transmission)) {
                transmissionOptions.push({
                    id: doc.id,
                    label: data.Transmission,
                    data: data,
                });
                transmissionAdded.push(data.Transmission);
            }
            if (!bodysAdded.includes(data.Body)) {
                bodyOptions.push({ id: doc.id, label: data.Body, data: data });
                bodysAdded.push(data.Body);
            }
        });


        setShowLitres(false);
        setLitre(null);
        setBodys(bodyOptions);
        if (transmissionOptions.length === 1) {
            setTransmission(transmissionOptions[0]);
        } else {
            setTransmission(null);
            setShowTransmission(true);
            setTransmissions(transmissionOptions);
        }

        if (bodyOptions.length === 1) {
            setBody(bodyOptions[0]);
            setShowPick(false);
            setShowBody(false);
        } else {
            setShowPick(false);
            setBody(null);
            setShowBody(true);
            setBodys(bodyOptions);
        }

        setVariant(variant);
    };

    const onBodyChange = async (body) => {
        setBody(body);

        let otherSnapshot = await search(year, make, model, variant, transmission, body, litre);

        const finalVehicles = [];
        const litresAdded = [];
        const litresOptions = [];
        otherSnapshot.forEach((doc) => {
            const data = doc.data();
            finalVehicles.push({ id: doc.id, label: data.Description, data: data });
            if (!litresAdded.includes(data.Litres)) {
                litresOptions.push({
                    id: doc.id,
                    label: data.Litres + 'L',
                    data: data,
                });
                litresAdded.push(data.Litres);
            }
        });
        setLitre(null);
        setShowPick(false);

        if (finalVehicles.length === 1) {
            setFinalPick(finalVehicles[0]);
            setShowPick(true);
        } else if (finalVehicles.length > 1) {
            setShowPick(true);
        }
        setFinalOptions(finalVehicles);

        if (litresOptions.length === 1) {
            setLitre(litresOptions[0]);
        } else {
            setShowLitres(true);
            setLitres(litresOptions);
        }
    };

    const onTransmissionChange = async (transmission) => {
        setTransmission(transmission);
        let otherSnapshot = await search(year, make, model, variant, transmission, body, litre);
        if (!otherSnapshot) return;
        const finalVehicles = [];
        const litresAdded = [];
        const litresOptions = [];
        otherSnapshot.forEach((doc) => {
            const data = doc.data();
            finalVehicles.push({ id: doc.id, label: data.Description, data: data });
            if (!litresAdded.includes(data.Litres)) {
                litresOptions.push({
                    id: doc.id,
                    label: data.Litres + 'L',
                    data: data,
                });
                litresAdded.push(data.Litres);
            }
        });

        setVariant(variant);


        if (litresOptions.length === 1) {
            setLitre(litresOptions[0]);
        } else {
            setShowLitres(true);
            setLitres(litresOptions);
        }

        if (finalVehicles.length === 1) {
            setFinalPick(finalVehicles[0]);
            setShowPick(true);
            setShowBody(false);
        } else {
            setShowPick(true);
        }
        setFinalOptions(finalVehicles);
    };

    const onLitreChange = async (litre) => {
        setLitre(litre);
        setFinalPick(null);

        let otherSnapshot = await search(year, make, model, variant, transmission, body, litre);

        const finalVehicles = [];
        otherSnapshot.forEach((doc) => {
            const finalData = doc.data();
            finalVehicles.push({
                id: doc.id,
                label: finalData.Description,
                data: finalData,
            });
        });
        setVariant(variant);
        if (finalVehicles.length === 1) {
            setFinalPick(finalVehicles[0]);
            setShowPick(true);
        } else if (finalVehicles.length > 1) {
            setShowPick(true);
        }
        setFinalOptions(finalVehicles);
    };

    const search = async (year, make, model, variant, transmission, body, litre) => {
        if (!make || !model || !variant) {
            return null;
        }
        let transmissionOption = null;
        if (transmission) {
            transmissionOption = transmission.label;
        }
        let bodyOption = null;
        if (body) {
            bodyOption = body.label;
        }
        let litreOption = null;
        if (litre) {
            litreOption = litre.data.Litres;
        }
        return priceGuideApi.getFinalOptions(
            'AU',
            year,
            make.label,
            model.label,
            variant.label,
            transmissionOption,
            bodyOption,
            litreOption,
        );
    };

    return (
        <div className="flex flex-col md:mx-10">
            <div className="flex flex-wrap md:mb-6 justify-start">
                <div className="w-full">
                    <p className="text-lg font-semibold text-gray-700">Select Your Vehicle From The Dropdowns</p>
                </div>
            </div>

            <div className="flex flex-row flex-col md:flex-row flex-wrap">
                <div className="w-full mb-4 md:w-1/2 md:pr-3">
                    <label
                        htmlFor="BuildYr"
                        className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                    >
                        Year
                    </label>
                    <Select placeholder={null} options={years} onChange={(selected) => onYearChange(selected.value)} onKeyDown={ (e) => handleUserKeyPress(e) } />
                </div>

                <div className="w-full mb-4 md:w-1/2 md:pl-3">
                    <label
                        htmlFor="Make"
                        className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                    >
                        Make
                    </label>
                    <Select
                        placeholder={null}
                        value={make}
                        getOptionValue={(option) => option.id}
                        id="Make"
                        name="Make"
                        options={makes}
                        onChange={(selected) => onMakeChange(selected)}
                        isLoading={loading}
                        isDisabled={!year}
                        onKeyDown={ (e) => handleUserKeyPress(e) }
                    />
                </div>

                <div className="w-full mb-4 md:w-1/2 md:pr-3">
                    <label
                        htmlFor="lastName"
                        className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                    >
                        Model
                    </label>
                    <Select
                        placeholder={null}
                        value={model}
                        getOptionValue={(option) => option.id}
                        options={models}
                        onChange={(selected) => onModelChange(selected)}
                        isLoading={loading}
                        isDisabled={!year || !make}
                        onKeyDown={ (e) => handleUserKeyPress(e) }
                    />
                </div>

                <div className="w-full mb-4  md:w-1/2 md:pl-3">
                    <label
                        htmlFor="Variant"
                        className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                    >
                        Variant
                    </label>
                    <Select
                        placeholder={null}
                        value={variant}
                        getOptionValue={(option) => option.id}
                        options={variants}
                        onChange={(selected) => onVariantChange(selected)}
                        isLoading={loading}
                        isDisabled={!year || !make || !model}
                        onKeyDown={ (e) => handleUserKeyPress(e) }
                    />
                </div>

                {showBody && (
                    <div className="w-full md:w-1/2 mb-4 md:pr-3">
                        <label
                            htmlFor="Body"
                            className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                        >
                            Body
                        </label>
                        <Select
                            placeholder={null}
                            value={body}
                            getOptionValue={(option) => option.id}
                            options={bodys}
                            onChange={(selected) => onBodyChange(selected)}
                            isLoading={loading}
                            onKeyDown={ (e) => handleUserKeyPress(e) }
                        />
                    </div>
                )}

                {showLitres && (
                    <div className="w-full md:w-1/2 mb-4 md:pl-3">
                        <label
                            htmlFor="Litres"
                            className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                        >
                            Litres
                        </label>
                        <Select
                            placeholder={null}
                            value={litre}
                            getOptionValue={(option) => option.id}
                            options={litres}
                            onChange={(selected) => onLitreChange(selected)}
                            isLoading={loading}
                            onKeyDown={ (e) => handleUserKeyPress(e) }
                        />
                    </div>
                )}

                {showTransmission && (
                    <div className="w-full mb-4 md:w-1/2 md:pr-3">
                        <label
                            htmlFor="Transmission"
                            className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                        >
                            Transmission
                        </label>
                        <Select
                            placeholder={null}
                            value={transmission}
                            getOptionValue={(option) => option.id}
                            options={transmissions}
                            onChange={(selected) => onTransmissionChange(selected)}
                            isLoading={loading}
                            onKeyDown={ (e) => handleUserKeyPress(e) }
                        />
                    </div>
                )}

                {showPick ? (
                    <div className="w-full mb-4">
                        <label
                            htmlFor="Vehicle"
                            className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2 "
                        >
                            Pick Vehicle
                        </label>
                        <Select
                            placeholder={null}
                            value={finalPick}
                            options={finalOptions}
                            getOptionValue={(option) => option.id}
                            isLoading={loading}
                            onChange={(selected) => setFinalPick(selected)}
                            onKeyDown={ (e) => handleUserKeyPress(e) }
                        />
                    </div>
                ) : null}
            </div>
        </div>
    );
};

export default ManualCarPicker;
