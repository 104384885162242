import './forgotPassword.scss';
import logo from '../../assets/logo.svg'
import { useState } from 'react';
import { UserService } from '../../services/userService';

import { XCircleIcon } from '@heroicons/react/solid'
import { useHistory } from 'react-router-dom';


function ForgotPasswordPage() {
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const userService = UserService.getInstance();
  const history = useHistory();

  const submit = async () => {
    if (!email || loading) {
      return;
    }
    setError("");
    setLoading(true);
    try {
     await userService.forgoPassword(email);
      setLoading(false);
      alert('An Email has been sent you to reset your password, you will be redirected now to the login page');

      history.replace('/login');
    }
    catch (e) {
      console.error("ERROR 4", e);
      setError(e);
      setLoading(false);

    }
  }
  return (
    <div className="min-h-screen bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <img
          className="mx-auto h-12 w-auto"
          src={logo}
          alt="Workflow"
        />
        <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">Forgot Password</h2>

      </div>

      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
          <div className="space-y-6" >
            <div>
              <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                Email address
              </label>
              <div className="mt-1">
                <input
                  id="email"
                  name="email"
                  type="email"
                  autoComplete="email"
                  required
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                  onChange={(ev) => {
                    setEmail(ev.target.value)
                  }}
                  onKeyUp={(ev)=> {
                    if(ev.key === "Enter") {
                        submit();
                    }
                  }}
                />
              </div>
            </div>


      

            <div>
              <button
                // type="submit"
                className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:opacity-75"
                disabled={!email   || loading? true : undefined}
                onClick={ () => {
                  submit()
                }}
              >
                { loading && <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
          <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
          <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
        </svg>}
                Submit
              </button>
            </div>
          </div>


        </div>

        <div className="error-wrapper">

{
    error &&
    <div className="rounded-md bg-red-50 p-4 flex justify-between  error">
        <div className="flex">
            <div className="flex-shrink-0">
                <XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
            </div>
            <div className="ml-3">
                <h3 className="text-sm font-medium text-red-800">{error}</h3>

            </div>
        </div>
    
    </div>
}

</div>

      </div>
    </div>
  )
}

export default ForgotPasswordPage;
