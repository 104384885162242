import React, { useLayoutEffect, useRef } from "react";
import './XYChartLoader.scss';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";

am4core.options.autoSetClassName = true;


export function XYChartLoaderComponent(props) {
    const chartRef = useRef(null);

    const getChartData = ( type = 'scatter' ) => {
        let data = [];
        if ( type === 'scatter' ) {
            let yData = 0;
    
            for (let i = 1; i < 50; i++) {
                yData += Math.max( Math.round((Math.random() < 0.5 ? 1 : -1) * Math.random() * 10), 10 );
                const xData = Math.random() * 1000;
                data.push({ xData, yData });
            }
        } else if ( type === 'line' ) {
            let yData = [ null, null, null, 12, 16, 20, 24, 28, 32, 28, 24, 20, 16, 12, 16, 20, 24, 28, 32, 36, null, null ];
            let xData = 0;
    
            for (let i = 0; i < yData.length; i++) {
                ++xData;
                data.push({ xData, yData: yData[i] });
            }
        } else if ( type === 'column' || type === 'bar' ) {
            // TODO: implement when needed
        }
        return data;
    }

    const defineChartAxis = ( chart, type  = 'scatter') => {
        if ( type === 'scatter' || type === 'line' ) {
            let value2Axis = chart.xAxes.push(new am4charts.ValueAxis());
            value2Axis.renderer.labels.template.fill = am4core.color("#d2d2d2");
            value2Axis.renderer.labels.template.fillOpacity = 0;
            value2Axis.renderer.grid.template.stroke = "#e2e2e2";
    
            let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
            valueAxis.renderer.labels.template.fill = am4core.color("#d2d2d2");
            valueAxis.renderer.labels.template.fillOpacity = 0;
            valueAxis.renderer.grid.template.stroke = "#e2e2e2";

            if ( type === 'line' ) valueAxis.min = 0;
        } else if ( type === 'column' || type === 'bar' ) {
            // TODO: implement when needed
        }
    }

    const defineChartSeries = ( chart, type = 'scatter' ) => {
        if ( type === 'scatter' || type === 'line' ) {
            let series = chart.series.push(new am4charts.LineSeries());
            series.dataFields.valueX = "xData";
            series.dataFields.valueY = "yData";
            series.stroke = am4core.color("#e2e2e2");
            
            if ( props.chartType === 'scatter' ) {
                series.strokeOpacity = 0;

                const bullet = series.bullets.push(new am4charts.Bullet());
                const bulletCircles = bullet.createChild(am4core.Circle);
                bulletCircles.stroke = am4core.color("#e2e2e2");
                bulletCircles.fill = am4core.color("#e2e2e2");
                bulletCircles.width = 10;
                bulletCircles.height = 10;
            } else {
                series.strokeWidth = 3.25;
            }
        } else if ( type === 'column' || type === 'bar' ) {
            // TODO: implement when needed
        }
    }

    useLayoutEffect( () => {
        let chart = am4core.create( props.chartId, am4charts.XYChart );
        chart.data = getChartData( props.chartType || 'scatter' );
        defineChartAxis( chart, props.chartType );
        defineChartSeries( chart, props.chartType );
        chartRef.current = chart;
        return () => {
            chart.dispose();
        };
    }, []);

    return (
        <div id={ props.chartId } className={ `bg-grey-900 text-grey-900 ${props.className} with-loader` } style={{ height: props.height || '400px' }}></div>
    );
}
