import React from 'react';
import { Table } from 'antd';
import 'antd/dist/antd.css';
import { clearUnits } from '../../utils/numbersFormat';
import { getDataSource } from '../../utils/insightsTableConstructor';


const BrandsComparison = ({ userBrands, brandComparisonData, getBrandInsights }) => {
    const brandsFirst = userBrands.map( brand => brand.name );
    const sorterCompare = ( a, b, key, isNumeric = 0, direction = 'ascend' ) => {
      const brandA = ( brandsFirst.indexOf( a['title'] ) >= 0 ? 1 : 0 );
      const brandB = ( brandsFirst.indexOf( b['title'] ) >= 0 ? 1 : 0 );

      if ( brandA && !brandB ) return (direction === 'ascend' ? -1 : 1);
      if ( brandB && !brandA ) return (direction === 'ascend' ? 1 : -1);


      const sideA = ( isNumeric !== 0 ? Number( clearUnits( a[key] ) ) : a[key] );
      const sideB = ( isNumeric !== 0 ? Number( clearUnits( b[key] ) ) : b[key] );

      if ( sideA < sideB ) return -1;
      if ( sideA > sideB ) return 1;
      return 0;
    }
    const columns = [
      { title: 'Brand', key: 'make', dataIndex: 'title', 
        sorter: { compare: (a, b, direction) => sorterCompare( a, b, 'title', 0, direction ), multiple: 1 },
        filters: brandComparisonData.map( entry => { return { text: entry.make, value: entry.make } } ).sort( (a,b, direction) => sorterCompare( a, b, 'text', 0, direction ) ),
        onFilter: (value, record) => record.title === value, filterSearch: true,
        render: ( text, record ) => {
          if (  brandsFirst.indexOf( record.title ) >= 0 ) return ( <div onClick={() => getBrandInsights(record.title)} className="text-blue-600 cursor-pointer"> {text} </div> )
          return text;
        }
      },
      { title: '% of Evals', key: 'percentageOfEvals', dataIndex: 'percentageOfEvals', align: 'center', sorter: { compare: (a, b, direction) => sorterCompare( a, b, 'percentageOfEvals', 1, direction ), multiple: 2 } },
      { title: '% Appraisals at Own Dealers', key: 'percentageOwnDealers', dataIndex: 'percentageOwnDealers', align: 'center', sorter: { compare: (a, b, direction) => sorterCompare( a, b, 'percentageOwnDealers', 1, direction ), multiple: 3 } },
      { title: 'Average Price', key: 'averagePrice', dataIndex: 'averagePrice',
        sorter: { compare: (a, b, direction) => sorterCompare( a, b, 'averagePrice', 1, direction ), multiple:4 }, align: 'center'
      },
      { title: 'Average Age', key: 'averageAge', dataIndex: 'averageAge', align: 'center', sorter: { compare: (a, b, direction) => sorterCompare( a, b, 'averageAge', 1, direction ), multiple: 5 } },
      { title: '% Traded', key: 'percentageTraded', dataIndex: 'percentageTraded', align: 'center', sorter: { compare: (a, b, direction) => sorterCompare( a, b, 'percentageTraded', 1, direction ), multiple: 6 } },
    ];

    const dataSource = getDataSource( brandComparisonData, 'make' ).sort( ( a,b,direction ) => sorterCompare( a, b, 'title', 0, direction ) );
    return (
        <div className="flex flex-col">
          <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
              <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                <Table dataSource={dataSource} columns={columns} />
              </div>
            </div>
          </div>
        </div>
      )
}

export default BrandsComparison
