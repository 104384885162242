import React, { useState, useEffect, useContext } from 'react';
import { PriceGuideSubmitContext } from '../../contexts/priceGuideSubmit';

const VinCarPicker = ({ setCarObject, loading }) => {
    const [vin, setVin] = useState();
    const { priceGuideSubmit, setPriceGuideSubmit } = useContext(PriceGuideSubmitContext);
    const handleUserKeyPress = (event) => {
        if ( event.key !== 'Enter' || vin?.length !== 17 ) return;
        setCarObject({ vin });
        setPriceGuideSubmit(true);
    };

    useEffect(() => {
        if (vin) {
            if (vin.length === 17) {
                setCarObject({
                    vin,
                });
            } else {
                setCarObject(null);
            }
        }
    }, [vin]);

    return (
        <div className="flex flex-col md:mx-10">
            <div className="flex flex-wrap md:mb-6 justify-start">
                <div className="w-full">
                    <p className="text-lg font-semibold text-gray-700">Enter Vehicle Identification Number</p>
                </div>
            </div>

            {!loading && (
                <div className="w-full mb-4 md:w-2/3 md:pr-3">
                    <label htmlFor="Vin" className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                        VIN
                    </label>

                    <div className="mt-1">
                        <input
                            type="text"
                            className="custom-input uppercase shadow-sm focus:ring-primary-500 focus:border-primary-500 block sm:text-sm border-gray-300 rounded-md md:w-2/3"
                            value={vin}
                            onChange={(e) => {
                                setVin(e.target.value);
                            }}
                            onKeyUp={ (e) => handleUserKeyPress(e) }
                        />
                    </div>
                </div>
            )}
        </div>
    );
};

export default VinCarPicker;
