import axios from 'axios';
import { UserService } from './services/userService';
const axiosInstance = axios.create({
    baseURL: 'https://evalexpert.io/v1/'
});

// request interceptor for adding token
axiosInstance.interceptors.request.use((config) => {
    // add token to request headers
    config.headers['Authorization'] = UserService.getInstance().backendToken
    return config;
  });

export default axiosInstance;