import React, { useState, useEffect, useContext } from 'react';
import Select from 'react-select';
import { PriceGuideSubmitContext } from '../../contexts/priceGuideSubmit';

const RegistrationCarPicker = ({ loading, setCarObject }) => {
    const [reg, setReg] = useState(null);
    //using province as name here to avoid the very confusing "state" nomenclature.
    const [province, setProvince] = useState(null);
    const { priceGuideSubmit, setPriceGuideSubmit } = useContext(PriceGuideSubmitContext);

    useEffect(() => {
        if (province && reg) {
            setCarObject({
                province,
                reg,
            });
        } else {
            setCarObject(null);
        }
    }, [province, reg]);

    const handleUserKeyPress = (event) => {
        if ( event.key !== 'Enter' || !province || !reg ) return;
        setCarObject({ province, reg });
        setPriceGuideSubmit(true);
    };

    return (
        <div className="flex flex-col md:mx-10">
            <div className="flex flex-wrap md:mb-6 justify-start">
                <div className="w-full">
                    <p className="text-lg font-semibold text-gray-700">Enter Registration and State</p>
                </div>
            </div>

            {!loading && (
                <div className="flex flex-row flex-col md:flex-row flex-wrap mb-4">
                    <div className="w-full md:w-1/2 md:pr-3">
                        <label
                            htmlFor="Registration"
                            className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2 "
                        >
                            Registration
                        </label>
                        <input
                            type="text"
                            className="w-full uppercase custom-input shadow-sm focus:ring-primary-500 focus:border-primary-500 block text-md border-gray-300 rounded-md"
                            value={reg}
                            onChange={(e) => {
                                setReg(e.target.value);
                            }}
                            onKeyUp={ (e) => handleUserKeyPress(e) }
                        />
                    </div>
                    <div className="w-full md:w-1/2 md:pl-3 child-select">
                        <label
                            htmlFor="Vin"
                            className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2 "
                        >
                            State
                        </label>
                        <Select
                            placeholder={null}
                            value={province}
                            options={[
                                { value: 'ACT', label: 'ACT' },
                                { value: 'NSW', label: 'NSW' },
                                { value: 'NT', label: 'NT' },
                                { value: 'QLD', label: 'QLD' },
                                { value: 'SA', label: 'SA' },
                                { value: 'TAS', label: 'TAS' },
                                { value: 'VIC', label: 'VIC' },
                                { value: 'WA', label: 'WA' },
                            ]}
                            onChange={(selected) => setProvince(selected)}
                            onKeyDown={ (e) => handleUserKeyPress(e) }
                        />
                    </div>
                </div>
            )}
        </div>
    );
};

export default RegistrationCarPicker;
