import React from 'react';
import { Table } from 'antd';
import 'antd/dist/antd.css';
import { getDataSource } from '../../utils/insightsTableConstructor';
import { clearUnits, formatDecimalWithUnit, formatWithCurrency } from '../../utils/numbersFormat';

const ModelInsight = ({ modelInsightData }) => {

    const sorterCompare = ( a, b, key, isNumeric = 0 ) => {
      const sideA = ( isNumeric !== 0 ? Number( clearUnits( a[key] ) ) : a[key] );
      const sideB = ( isNumeric !== 0 ? Number( clearUnits( b[key] ) ) : b[key] );

      if ( sideA < sideB ) return -1;
      if ( sideA > sideB ) return 1;
      return 0;
    }
    const columns = [
      { title: 'Variant', key: 'variant', dataIndex: 'title', 
        sorter: { compare: (a, b) => sorterCompare( a, b, 'title', 0 ), multiple: 1 },
        filters: modelInsightData.map( entry => { return { text: entry.variant, value: entry.variant } } ).sort( (a,b) => sorterCompare( a, b, 'text', 0 ) ),
        onFilter: (value, record) => record.title === value, filterSearch: true,
      },
      { title: '% of Evals', key: 'percentageOfEvals', dataIndex: 'percentageOfEvals', align: 'center', sorter: { compare: (a, b) => sorterCompare( a, b, 'percentageOfEvals', 1 ), multiple: 2 } },
      { title: '% Appraisals at Own Dealers', key: 'percentageOwnDealers', dataIndex: 'percentageOwnDealers', align: 'center', sorter: { compare: (a, b) => sorterCompare( a, b, 'percentageOwnDealers', 1 ), multiple: 3 } },
      { title: 'Average Price', key: 'averagePrice', dataIndex: 'averagePrice',
        sorter: { compare: (a, b) => sorterCompare( a, b, 'averagePrice', 1 ), multiple:4 }, align: 'center'
      },
      { title: '% Traded', key: 'percentageTraded', dataIndex: 'percentageTraded', align: 'center', sorter: { compare: (a, b) => sorterCompare( a, b, 'percentageTraded', 1 ), multiple: 6 } },
    ];
    const originalDataSource = getDataSource( modelInsightData, 'variant', 1 );
    let dataSource = {};
    let variantRecords = {};
    originalDataSource.map( variant => {
      const groupTitle = variant.title.slice(5, variant.title.length);
      if ( !dataSource[groupTitle] ) dataSource[groupTitle] = { variant: groupTitle };
      if ( !Array.isArray( variantRecords[groupTitle] ) ) variantRecords[groupTitle] = [];
      variantRecords[groupTitle].push( variant );
      if ( !!variant.percentageOfEvals || parseFloat(variant.percentageOfEvals) === 0 ) {
        if ( !dataSource[groupTitle]?.percentageOfEvals ) dataSource[groupTitle].percentageOfEvals = parseFloat(variant.percentageOfEvals);
        else dataSource[groupTitle].percentageOfEvals += parseFloat(variant.percentageOfEvals);
      }
      if ( !!variant.percentageOwnDealers || parseFloat(variant.percentageOwnDealers) === 0 ) {
        if ( !dataSource[groupTitle]?.percentageOwnDealers ) dataSource[groupTitle].percentageOwnDealers = parseFloat(variant.percentageOwnDealers);
        else dataSource[groupTitle].percentageOwnDealers += parseFloat(variant.percentageOwnDealers);
        if ( !dataSource[groupTitle].percentageOwnDealersValidCount ) dataSource[groupTitle].percentageOwnDealersValidCount = 1;
        else dataSource[groupTitle].percentageOwnDealersValidCount += 1;
      }
      if ( !!variant.averagePrice || parseInt( variant.averagePrice ) === 0 ) {
        if ( !dataSource[groupTitle]?.averagePrice ) dataSource[groupTitle].averagePrice = parseInt(variant.averagePrice);
        else dataSource[groupTitle].averagePrice += parseInt(variant.averagePrice);
        if ( !dataSource[groupTitle].averagePriceValidCount ) dataSource[groupTitle].averagePriceValidCount = 1;
        else dataSource[groupTitle].averagePriceValidCount += 1;
      }
      if ( !!variant.percentageTraded || parseFloat( variant.percentageTraded ) === 0 ) {
        if ( !dataSource[groupTitle]?.percentageTraded ) dataSource[groupTitle].percentageTraded = parseFloat(variant.percentageTraded);
        else dataSource[groupTitle].percentageTraded += parseFloat(variant.percentageTraded);
        if ( !dataSource[groupTitle].percentageTradedValidCount ) dataSource[groupTitle].percentageTradedValidCount = 1;
        else dataSource[groupTitle].percentageTradedValidCount += 1;
      }
    } );
    const finalData = [];
    Object.keys( dataSource ).forEach( variant => {
      const entry = dataSource[variant];
      if ( !!entry.averagePriceValidCount ) entry.averagePrice /= entry.averagePriceValidCount;
      if ( !!entry.percentageTradedValidCount ) entry.percentageTraded /= entry.percentageTradedValidCount;
      if ( !!entry.percentageOwnDealers ) entry.percentageOwnDealers /= entry.percentageOwnDealersValidCount;
      finalData.push( entry );
    } );
    dataSource = getDataSource( finalData, 'variant' ).sort( ( a,b ) => sorterCompare( a, b, 'title', 0 ) );
    const getRecordExpandable = ( record ) => {
      const yearsVariants = variantRecords[record.title];
      const columns = [
        { title: 'Year', dataIndex: 'year', key: 'year', sorter: { compare: (a, b) => sorterCompare( a, b, 'year', 0 ), multiple: 1 } },
        { title: '% of Evals', dataIndex: 'percentageOfEvals', key: 'percentageOfEvals', align: 'center', sorter: { compare: (a, b) => sorterCompare( a, b, 'percentageOfEvals', 1 ), multiple: 2 } },
        { title: '% Appraisals at Own Dealers', dataIndex: 'percentageOwnDealers', key: 'percentageOwnDealers', align: 'center', sorter: { compare: (a, b) => sorterCompare( a, b, 'percentageOwnDealers', 1 ), multiple: 3 } },
        { title: 'Average Price', dataIndex: 'averagePrice', key: 'averagePrice', align: 'center', sorter: { compare: (a, b) => sorterCompare( a, b, 'averagePrice', 1 ), multiple:4 } },
        { title: '% Traded', dataIndex: 'percentageTraded', key: 'percentageTraded', align: 'center', sorter: { compare: (a, b) => sorterCompare( a, b, 'percentageTraded', 1 ), multiple: 5 } },
      ];
      const data = [];
      yearsVariants.forEach( ( variant, index ) => {
        data.push({
          key: index,
          year: variant.title.split(' ')[0],
          percentageOfEvals: formatDecimalWithUnit( variant.percentageOfEvals, '%', 0 ),
          percentageOwnDealers: formatDecimalWithUnit( variant.percentageOwnDealers, '%', 0 ),
          averagePrice: formatWithCurrency( variant.averagePrice, 'USD' ),
          percentageTraded: formatDecimalWithUnit( variant.percentageTraded, '%', 0 ),
        });
      } );
      data.sort( ( a,b ) => sorterCompare( a, b, 'year', 0 ) ).reverse();
      return <Table className="variant-years-table" columns={columns} dataSource={data} pagination={false} style={{ margin: '5px', width: '94.5%', paddingTop: '15px', paddingBottom: '15px' }} />;
    }


    return (
      <div className="flex flex-col">
        <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
              <Table dataSource={dataSource} columns={columns} expandable={{ expandedRowRender: record => getRecordExpandable( record ) }} />
            </div>
          </div>
        </div>
      </div>
    )
}

export default ModelInsight
