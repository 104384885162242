import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4maps from '@amcharts/amcharts4/maps';
import am4geodata_australiaLow from '@amcharts/amcharts4-geodata/australiaLow';
import { MapChartLoaderComponent } from '../Loaders/MapChartLoader/MapChartLoader';
import api from '../../api/priceGuide';
import { formatDecimalWithUnit, formatNumberWithUnit, roundNumber } from '../../utils/numbersFormat';

const Map = ({ vehicleCode, km, className, priceList }) => {
    const chartRef = useRef(null);

    useEffect(() => {
        if (!priceList) return;
        let map = am4core.create('chartdiv', am4maps.MapChart);

        map.seriesContainer.draggable = false;
        map.chartContainer.wheelable = false;
        map.maxZoomLevel = 1;

        map.geodata = am4geodata_australiaLow;

        map.projection = new am4maps.projections.Miller();
        let polygonSeries = map.series.push(new am4maps.MapPolygonSeries());
        if (priceList && priceList.length > 0) {
            const auPriceAverage = priceList.map((price) => price.value).reduce((a, b) => a + b, 0) / priceList.length;
            polygonSeries.data = priceList;

            let labelSeries = map.series.push(new am4maps.MapImageSeries());
            let labelTemplate = labelSeries.mapImages.template.createChild(am4core.Label);
            labelTemplate.horizontalCenter = 'middle';
            labelTemplate.verticalCenter = 'top';
            labelTemplate.fontSize = 8;
            labelTemplate.background.fill = am4core.color('#FFF');
            labelTemplate.padding(2, 4, 2, 4);

            polygonSeries.calculateVisualCenter = true;
            polygonSeries.events.on('inited', function () {
                polygonSeries.mapPolygons.each(function (polygon) {
                    let label = labelSeries.mapImages.create();
                    label.background.fill = am4core.color('#FFF');
                    // let state = polygon.dataItem.dataContext.id.split("-").pop();
                    const diff = ((polygon.dataItem.dataContext.value - auPriceAverage) * 100) / auPriceAverage;
                    if (diff > 0) {
                        label.stroke = am4core.color('#059669');
                    } else {
                        label.stroke = am4core.color('#dc2626');
                    }
                    label.latitude = polygon.visualLatitude;
                    label.longitude = polygon.visualLongitude;
                    label.children.getIndex(0).text = (diff > 0 ? '+' : '') + formatDecimalWithUnit(diff, '%', 0);
                });
            });
        }

        polygonSeries.useGeodata = true;

        polygonSeries.heatRules.push({
            property: 'fill',
            target: polygonSeries.mapPolygons.template,
            min: am4core.color('#90e0ef'),
            max: am4core.color('#0077b6'),
            logarithmic: true,
        });

        let polygonTemplate = polygonSeries.mapPolygons.template;
        polygonTemplate.tooltipText = '{name}: ${value}';

        chartRef.current = map;

        return () => {
            chartRef.current && chartRef.current.dispose();
        };
    }, [priceList]);

    return (
        <div className={className || ''}>
            <div
                id="chartdiv"
                className={`chartdiv ${priceList === null ? 'hidden' : ''}`}
                style={{ height: '320px' }}
            ></div>
            {priceList === null && (
                <div className="mt-4">
                    <MapChartLoaderComponent
                        height="320px"
                        className="w-full"
                        chartId="pricePerStateLoader"
                    ></MapChartLoaderComponent>
                </div>
            )}
        </div>
    );
};

export default Map;
